import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';

export const Header = styled.div`
    width: 100%;
    height: 56px;
    margin-top: -8px;
    background-color: #2D9CDB;
    display: flex;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    margin-bottom: 4px;
`;

export const H1 = styled.h1`
    font-family: Open Sans;
    font-weight: 600;
    font-size: 14px;
    color: var(--ahg-secondary-lighten5);
    margin-left: 16px;
    margin-top: 18px;
`;

export const Close = styled(CloseIcon)`
    color: var(--ahg-secondary-lighten5);
    position: absolute;
    right: 16px;
    cursor: pointer;
`;

