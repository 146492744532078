import React from 'react';
import { StatusActive, StatusInactive, StatusWrapper } from './styles';

function DeviceStatus({ value }) {

    return (
        <StatusWrapper>
            {value.toUpperCase() === 'ATIVO' ? <StatusActive>{value}</StatusActive> : <StatusInactive>{value}</StatusInactive>}
        </StatusWrapper>

    );
}

export default DeviceStatus;
