import React from 'react';
import SelectMultiple from '../Layout/SelectMultiple';
import Radio from '../Layout/Radio';
import Grid from '@mui/material/Grid';
import i18n from '../../helpers/i18n';
import { TitleH3, TitleSpan, gridItemStyle } from '../CreateDeviceContent/styles';


export default function AuthorizedCollaboratorsSection({
        formik, 
        employees, 
        isLoadingEmployees, 
        handleScroll,
        isLoadingLocationsAndCnpjs, 
        locations,
        filterTypes
    }) {

    return (
        <Grid style={gridItemStyle} item xs={12} md={6}>
            <TitleH3>
                {i18n.t('pages.newDevice.authorizedEmployee')} <TitleSpan>{i18n.t('pages.newDevice.mandatory')}</TitleSpan>
            </TitleH3>
            <Radio
                sx={{ display: 'flex', flexDirection: 'row' }}
                values={filterTypes}
                value={formik.values.collaboratorsType}
                onChange={(event) => formik.setFieldValue('collaboratorsType', event.target.value)}
            />

            {formik.values.collaboratorsType === 'by_employee' ?
                <>
                    <SelectMultiple
                        items={employees}
                        MenuProps={{
                            PaperProps: {
                                onScroll: handleScroll,
                            },
                        }}
                        id="employees"
                        disabled={isLoadingEmployees}
                        title={i18n.t('pages.newDevice.selectEmployee')}
                        value={formik.values.employees}
                        renderValue={(selected) => `${selected.length} ${i18n.t('pages.newDevice.selectedLabel')}`}
                        onChange={(event) => formik.setFieldValue('employees', event.target.value)}
                        loading={isLoadingEmployees}
                    />
                </> :
                <>
                    <SelectMultiple
                        items={locations}
                        id="locations"
                        disabled={isLoadingLocationsAndCnpjs}
                        title={i18n.t('pages.newDevice.selectLocation')}
                        value={formik.values.locations}
                        renderValue={(selected) => selected.join(', ')}
                        onChange={(event) => formik.setFieldValue('locations', event.target.value)}
                    />
                </>
            }
        </Grid>
    )

}