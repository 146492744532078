import React from 'react';

import { Header, Footer, H1, Close } from './styles';
import { Button } from '../Layout';
import i18n from '../../helpers/i18n';

const MenuFilterWindow = ({ clearFilter, ...props }) => {
    const leftZero = (val) => {
        return val > 9 ? val : `0${val}`;
    };

    const handleCloseClear = () => {
        clearFilter(props.filterType)
        props.handleClose();
    }
    
    const handleApply = () => {
        let text = '';
        switch (props.filterType) {
            case 'signinDate':
                const start = props.filterDate.start;
                const end = props.filterDate.end;
                const startDate = `${leftZero(start.$D)}/${leftZero(start.$M + 1)}`;
                const endDate = `${leftZero(end.$D)}/${leftZero(end.$M + 1)}`;
    
                text = `${i18n.t('filter.createDateResult')} : ${startDate} - ${endDate}`
                break;
            case 'deviceType':
                const devices = props.filterDevice.join(', ').replace(/,(?=[^,]+$)/, ` ${i18n.t('common.and')} `);
                text = `${i18n.t('filter.deviceType')}: ${devices}`
                break;
            case 'status':
                text = `${i18n.t('filter.status')} : ${props.filterStatus.toLowerCase() === 'ativo'
                    ? i18n.t('filter.active')
                    : i18n.t('filter.inactive')}`
                break;
            default:
                text = '';
                break;
        }
    
        const filter = {
            text: text,
            type: props.filterType
        };
        props.toggleFilterItem(filter, true);
        props.handleClose();
    };
    

    return (
        <>
            <Header>
                <H1>
                    {props.title}
                    <Close fontSize="small" onClick={handleCloseClear} />
                </H1>
            </Header>
            {props.children}
            <Footer>
                <Button disabled={props.actionDisabled} onClick={handleApply}> Aplicar </Button>
            </Footer>
        </>
    )
};

export default MenuFilterWindow;