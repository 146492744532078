import { styled } from '@mui/system';

export const datePickerStyles = {
    marginTop: '16px !important',
    marginLeft: '16px !important',
    width: 'calc(100% - 32px)',
    fontSize: 12,
    '& .MuiInputBase-root': {
        flexDirection: 'row-reverse',
    },
    '& .MuiButtonBase-root': {
        color: '#2D9CDB',
    },
};

export const DatePickerWrapper = styled('div')(datePickerStyles);
