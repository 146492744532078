const BASE_PATH = (window.location !== window.parent.location)
                ? document.referrer
                : `https://${window.location.hostname.replace('dispositivos-online.', '')}/`;
                
const consts = {
    BASE_PATH,
    BASE_PATH_API: BASE_PATH,
    MYAHGORA_URL: `${BASE_PATH}painel/dashboard`
}

export default consts;