import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const StyledTypography = styled(Typography)({
    '.MuiTypography-root': {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 'normal'
    }
});
