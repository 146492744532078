import React from 'react';

import { Content, FilterIcon } from './styles';
import FilterAddButton from '../FilterAddButton';
import FilterItem from '../FilterItem';

const FilterBar = ({ filters }) => {

    const {toggleFilterItem, filterList } = filters;

    return (
        <Content>
            <FilterIcon />
            {filterList.map((item, key) =>
            (
                <FilterItem item={item} key={key} toggleFilterItem={toggleFilterItem} />
            )
            )}
            <FilterAddButton
                filters={filters}
            />
        </Content>
    );
}

export default FilterBar;