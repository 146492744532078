
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  active: Yup.boolean(),
  deviceType: Yup.string().required('Tipo de dispositivo é obrigatório'),
  cnpj: Yup.string().required('CNPJ é obrigatório'),
  name: Yup.string().required('Nome do dispositivo é obrigatório'),
  timezone: Yup.string().required('Timezone é obrigatório'),
  activationKey: Yup.string(),
  expirationDate: Yup.boolean(),
  filterType: Yup.string(),
  employees: Yup.array(),
  locations: Yup.array(),
  enablePunchByID: Yup.boolean(),
  enableAutoUpdate: Yup.boolean(),
  enableTimezone: Yup.boolean(),
  enableTripleValidation: Yup.boolean(),
  enablePunchLogs: Yup.boolean(),
  enableBackup: Yup.boolean(),
  break_device: Yup.boolean(),
  online_only: Yup.boolean(),
  autoDateTime: Yup.boolean(),
  only_inside_geofence: Yup.boolean(),
  range_ip: Yup.boolean(),
});