import React from 'react';

import { Close, Content, P } from './styles';

const FilterItem = ({toggleFilterItem, item}) => {

    function handleClose(item){
        toggleFilterItem(item, false);
    }

    return (
        <Content>
            <P>{item.text}</P>
            <Close onClick={() => handleClose(item)} />
        </Content>
    );
};

export default FilterItem;