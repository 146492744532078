
import { Info } from '@mui/icons-material';
import { TooltipStyled, infoStyle} from './styles'

const InfoTooltip = ({tooltip, placement}) => {
    return (
        <TooltipStyled 
            title={tooltip}
            placement={placement || "right"}
            arrow
            >
            <Info 
               sx={infoStyle} 
             />
        </TooltipStyled>
    )
}

export default InfoTooltip;