import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';

export const StyledIconButton = styled(IconButton)(({ copied }) => ({
  gap: '2px',
  font: 'normal normal 400 14px/24px Open Sans',
  padding: '8px',
  backgroundColor: copied ? 'var(--ahg-cyan-lighten4)' : 'inherit',
  color: copied ? 'var(--ahg-gray-darken2)' : 'inherit',
  borderRadius: '4px',
  width: '100px',
  maxWidth: '100px',
}));

export const truncate = {
    display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '70px' 
}
