
import React from 'react';
import { Grid, Breadcrumbs } from '@mui/material';
import ButtonDropdown from '../ButtonDropdown';
import i18n from '../../helpers/i18n';
import {
    Container,
    BreadcrumbsContainer,
    TitleTypography,
    BreadcrumbLink,
    ActualBreadcrumb,
    ButtonsContainer,
    HeaderItems,
} from './styles';

const Header = ({ title = "", breadCrumbs = [], showButtons = false, deviceName = "" }) => {
    const devicesMenu = [
        { title: i18n.t('home.newDevice'), href: '/create' },
        { title: i18n.t('home.multipleDevice'), href: '/multiple' },
        { title: i18n.t('home.importDevice'), href: 'https://app.ahgora.com.br/importa?import=batidaonline', openNewTab: true },
    ];

    const downloadMenu = [
        { title: i18n.t('home.deviceAh01'), href: 'https://app.ahgora.com.br/files/Ahgora-AH01.msi', openNewTab: true },
        { title: i18n.t('home.manualAh01'), href: 'https://9366697.fs1.hubspotusercontent-na1.net/hubfs/9366697/%5BCLIENTES%5D%20Manuais/DHW%20M005%20-%20Manual%20de%20instala%C3%A7%C3%A3o%20do%20Ah01%20(1).pdf', openNewTab: true },
        { title: i18n.t('home.thirdPartyDevice'), href: 'https://app.ahgora.com.br/downloads/AhgoraPontoWeb-instalador.exe', openNewTab: true },
    ];


    return (
        <Grid item xs={12}>
            <Container>
                <BreadcrumbsContainer>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <BreadcrumbLink underline="hover" color="inherit" href="https://app.ahgora.com.br/painel">
                            {i18n.t('breadcrumb.pontoweb')}
                        </BreadcrumbLink>
                        <BreadcrumbLink underline="hover" color="inherit" href="https://app.ahgora.com.br/equipamentos">
                            {i18n.t('breadcrumb.equipment')}
                        </BreadcrumbLink>
                        {breadCrumbs.map(item => item.href ? (
                            <BreadcrumbLink key={item.title.split(' ').join('-')} to={item.href} color="textPrimary">
                                {item.title}
                            </BreadcrumbLink>
                        ) : (
                            <ActualBreadcrumb key={item.title.split(' ').join('-')}>
                                {item.title}
                            </ActualBreadcrumb>
                        ))}
                    </Breadcrumbs>
                </BreadcrumbsContainer>
                <HeaderItems>
                    <TitleTypography>{title} {deviceName ? deviceName : ''}</TitleTypography>
                    {showButtons && (
                        <ButtonsContainer>
                            <ButtonDropdown key='downloadsBtn' buttonType='outlined' title={i18n.t('home.downloadButton')} items={downloadMenu} />
                            <ButtonDropdown key='devicesBtn' title={i18n.t('home.createDeviceButton')} items={devicesMenu} />
                        </ButtonsContainer>
                    )}
                </HeaderItems>
            </Container>
        </Grid>
    );

}

export default Header;
