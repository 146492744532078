import React, { useState } from 'react';
import { MenuItem, Menu } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { MenuStyled, ButtonStyled, IconStyled } from './styles';
import i18n from '../../helpers/i18n';

import FilterDate from '../FilterDate';
import FilterDevice from '../FilterDevice';
import MenuFilterWindow from '../MenuFilterWindow';
import FilterStatus from '../FilterStatus';

const FilterAddButton = ({ filters }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuType, setMenuType] = useState('list');
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMenuType('list');
    };

    const handleClickMenuItem = (type = 'list') => {
        setMenuType(type);
    };

    const MenuContent = () => {
        switch (menuType) {
            case 'status':
                return (
                    <MenuFilterWindow
                        title={i18n.t('filter.status')}
                        filterList={filters.filterList}
                        handleClose={handleClose}
                        toggleFilterItem={filters.toggleFilterItem}
                        filterType={menuType}
                        actionDisabled={filters.filterStatus === null ? true : false}
                        filterStatus={filters.filterStatus}
                        clearFilter={filters.clearFilter}
                    >
                        <FilterStatus
                            filterStatus={filters.filterStatus}
                            setFilterStatus={filters.setFilterStatus}
                        />
                    </MenuFilterWindow>
                );
            case 'deviceType':
                return (
                    <MenuFilterWindow
                        title={i18n.t('filter.deviceType')}
                        filterList={filters.filterList}
                        handleClose={handleClose}
                        toggleFilterItem={filters.toggleFilterItem}
                        filterType={menuType}
                        actionDisabled={filters.filterDevice.length < 1}
                        filterDevice={filters.filterDevice}
                        clearFilter={filters.clearFilter}
                    >
                        <FilterDevice
                            filterDevice={filters.filterDevice}
                            setFilterDevice={filters.setFilterDevice}
                        />
                    </MenuFilterWindow>
                );
            case 'signinDate':
                return (
                    <MenuFilterWindow
                        title={i18n.t('filter.createDate')}
                        filterList={filters.filterList}
                        handleClose={handleClose}
                        toggleFilterItem={filters.toggleFilterItem}
                        filterType={menuType}
                        actionDisabled={filters.filterDate.start !== null && filters.filterDate.end !== null ? undefined : true}
                        filterDate={filters.filterDate}
                        clearFilter={filters.clearFilter}
                    >
                        <FilterDate
                            filterDate={filters.filterDate}
                            setFilterDate={filters.setFilterDate}
                        />
                    </MenuFilterWindow>
                );
            default:
                return (
                    <>
                        <MenuItem onClick={() => handleClickMenuItem('signinDate')}>{i18n.t('filter.createDate')}</MenuItem>
                        <MenuItem onClick={() => handleClickMenuItem('deviceType')}>{i18n.t('filter.deviceType')}</MenuItem>
                        <MenuItem onClick={() => handleClickMenuItem('status')}>{i18n.t('filter.status')}</MenuItem>
                    </>
                );
        }
    };

    return (
        <>
            <ButtonStyled
                id="add-filter-btn"
                aria-controls={open ? 'add-filter-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <IconStyled>
                    <AddIcon fontSize="small" />
                </IconStyled>
                {i18n.t('filter.addFilter')}
            </ButtonStyled>
            <MenuStyled>
                <Menu
                    id="add-filter-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'add-filter-btn',
                    }}
                >
                    <MenuContent />
                </Menu>
            </MenuStyled>
        </>
    );
};

export default FilterAddButton;
