import AlertModal from '../AlertModal';



export default function MultipleDevicesCreatedAlert({ isOpen, devicesLenght, onCreated }) {
    return (
        <AlertModal
            title="Dispositivos criados com sucesso!"
            buttonText='Ok, entendi'
            children={`Foram criados ${devicesLenght} dispositivos.`}
            buttonAction={onCreated}
            visible={isOpen} />
    )
}