import { styled } from '@mui/system';
import { Button } from '@mui/material';

const defineButtonStyle = (props) => {
    switch(props.type) {
        case 'error': 
            return {
                backgroundColor: 'var(--ahg-error-base)',
                fontWeight: 600,
                '&:hover': {
                    backgroundColor: 'var(--ahg-error-lighten1)'
                }
            };
        case 'success':
            return {
                backgroundColor: 'var(--ahg-success-base)',
                '&:hover': {
                    backgroundColor: 'var(--ahg-success-lighten1)'
                }
            };
        case 'text':
            return {
                color: 'var(--ahg-primary-base)',
                backgroundColor: 'transparent',
                '&:hover': {
                    textDecoration: 'underline',
                    backgroundColor: 'transparent'
                }
            };
        case 'warning':
            return {
                backgroundColor: 'var(--ahg-warning-base)',
                '&:hover': {
                    backgroundColor: 'var(--ahg-warning-lighten1)'
                }
            };
        case 'outlined':
            return {
                backgroundColor: 'transparent',
                border: 'solid 1px var(--ahg-primary-base)',
                color: 'var(--ahg-primary-base)',
                padding: '8px 15px 7px 15px',
                "&:hover": {
                    backgroundColor: 'var(--ahg-primary-base)',
                    color: 'var(--ahg-gray-lighten5)',
                    border: 'none',
                    padding: '9px 16px 8px 16px'
                }
            };
        default:
            return {};
    }
};

export const StyledButton = styled(Button)(({ theme, type }) => ({
    backgroundColor: 'var(--ahg-primary-base)',
    color: 'var(--ahg-gray-lighten5)',
    fontFamily: 'Open Sans',
    textTransform: 'none',
    fontWeight: 600,
    marginRight: 16,
    padding: '9px 16px 8px 16px',
    '&:hover': {
        backgroundColor: 'var(--ahg-primary-lighten1)'
    },
    '&:disabled': {
        backgroundColor: 'var(--ahg-gray-lighten3)',
        color: 'var(--ahg-secondary-base)',
        fontWeight: 400
    },
    ...defineButtonStyle({ type })
}));
