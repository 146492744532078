
export const useStyles = {
  btnDropdownPrimary: {
    borderRadius: '4px',
    backgroundColor: 'var(--ahg-primary-base)',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
    font: 'normal normal 600 14px  Open Sans',
    textTransform: 'none',
    color: 'var(--ahg-gray-lighten5)',
    display: 'flex',
    padding: '6px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8',
    "&:hover": {
      backgroundColor: 'transparent',
      border: '1px solid var(--ahg-primary-base)',
      color: 'var(--ahg-primary-base)',
    },
  },
  btnDropdownOutlined: {
    borderRadius: '4px',
    border: '1px solid var(--ahg-primary-base)',
    backgroundColor: 'transparent',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
    font: 'normal normal 600 14px  Open Sans',
    textTransform: 'none',
    color: 'var(--ahg-primary-base)',
    display: 'flex',
    padding: '6px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8',
    "&:hover": {
      backgroundColor: 'var(--ahg-primary-base)',
      border: '1px solid var(--ahg-primary-base)',
      color: 'var(--ahg-gray-lighten5)',
    },
  },
  menuDropdown: {
    font: 'normal normal 400 14px  Open Sans',
    color: 'var(--ahg-waterloo-base)',
    letterSpacing: '0.3',
    padding: '8px 16px',
    inset: '0px auto auto 0px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '10',
    alignSelf: 'stretch'
  },
  icon: {
    marginLeft: '8px',
  }
}
