import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

export const Content = styled.div`
    background-color: var(--ahg-primary-darken1);
    border-radius: 14px;
    height: 28px;
    display: flex;
    padding-left: 12px;
    padding-right: 8px;
    align-items: center;
    margin-left: 16px;
`;

export const P = styled.span`
    color: var(--ahg-gray-lighten5);
    font-family: Open Sans;
    font-weight: 600;
    font-size: 12px;
    cursor: default;
`;

export const Close = styled(CloseIcon)`
    color: var(--ahg-primary-darken1);
    right: 12px;
    cursor: pointer;
    border-radius: 100%;
    font-size: 14px !important;
    margin-left: 8px;
    background-color: var(--ahg-gray-lighten5);
`;