import AlertModal from '../AlertModal';

export default function DeviceDeleteConfirmationAlert({ isOpen, onClose }) {

    return (
        <AlertModal
            title="Dispositivo arquivado com sucesso!"
            children={'O dispositivo foi arquivado com sucesso.'}
            buttonText='Ok, entendi'
            buttonAction={onClose}
            visible={isOpen} />
    )
}