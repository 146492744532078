import { styled } from '@mui/material/styles';
import { Alert as MuiAlert } from '@mui/material';

export const AlertFeedback = styled(MuiAlert)({
    color: 'var(--ahg-waterloo-base)',
    backgroundColor: 'var(--ahg-primary-lighten5)',
    font: 'normal normal 400 14px/24px Open Sans',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: '4px',
    margin: '16px'
});