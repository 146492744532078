import React, { useState } from 'react';
import { CopyAll } from '@mui/icons-material';
import {StyledIconButton, truncate} from './styles';

function CopyButton({ value }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = async (value) => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    } catch (err) {
      console.error('Erro ao copiar:', err);
    }
  };

  return (
    <div>
  <StyledIconButton
    onClick={() => handleCopy(value)}
    aria-label={`copy/${value}`}
    copied={copied ? 1 : 0}
  >
    <span style={truncate}>
      {copied ? 'Copiado!' : value}
    </span>
    {copied === false && <CopyAll fontSize="small" />}
  </StyledIconButton>
</div>
  );
}

export default CopyButton;
