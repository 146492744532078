import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Container, Button } from '../Layout';
import { gridContainerStyle } from './styles';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import i18n from '../../helpers/i18n';
import { createDevice, getEmployees, getLocationsAndCnpj } from './CreateDeviceContent.queries';
import { useFormik } from 'formik';
import { validationSchema } from './validationSchema';
import { RestrictionSection, AuthorizedCollaboratorsSection, ControllerSection, DeviceSection } from '../CreateEditSections/';
import { deviceTypes, filterTypes } from '../../helpers/consts/deviceConstants';
import { useNavigate } from 'react-router-dom';
import DeviceCreatedAlert from '../DeviceCreatedAlert/DeviceCreatedAlert';

const CreateDeviceContent = () => {
    const [offset, setOffset] = useState(0);
    const navigate = useNavigate();

    const [isOpenCreateDevice, setIsOpenCreateDevice] = useState(false);

    const { data: dataEmployees, isLoading: isLoadingEmployees } = useQuery({
        queryKey: ['funcionarios', offset],
        queryFn: () => getEmployees({ limit: 20, offset }),
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        retry: false
    });

    const { data: dataLocationsAndCnpj, isLoading: isLoadingLocationsAndCnpjs } = useQuery({
        queryKey: ['locations'],
        queryFn: getLocationsAndCnpj,
        refetchOnWindowFocus: false,
        retry: false
    });

    const mutationCreateDevice = useMutation({
        mutationFn: createDevice,
        onSuccess: () => {
            setIsOpenCreateDevice(true)
        },
        onError: (e) => {
            alert('Falha ao criar dispositivo.', e);
        },
    });

    const [employees, setEmployees] = useState([]);
    const [locations, setLocations] = useState([]);
    const [cnpjs, setCnpjs] = useState([]);

    const formik = useFormik({
        initialValues: {
            deviceType: '',
            cnpj: '',
            name: '',
            timezone: '',
            activationKey: '',
            active: true,
            expirationDate: false,
            startPeriod: null,
            endPeriod: null,

            collaboratorsType: 'by_employee',
            employees: [],
            locations: [],

            //Crontroller
            enable_password_punch: false, //Ativar batida por matrícula e senha
            break_device: false, //Ativar dispositivo de pausa NR17
            enable_auto_update: false, //Ativar atualização automática (Tablet Ahgora)
            default_computer: false, //Ativar batida online padrão por link
            timezone_filter: false,  //Ativar fuso horário como filtro              
            triple_validation: false, //Ativar validação tripla para múltiplos usuários
            enable_punches_logs: false, //ativar logs de batidas
            enable_punch_backup: false, //Ativar download de arquivo com batida de ponto (Solicitar donwload do arquivo)

            //Restriction
            online_only: false, //Com o dispositivo conectado à internet
            auto_timezone: false, //Com data, hora e fuso automáticos
            only_with_location: false, //Com geolocalização no dispositivo
            range_ip: false,
            ips: [], //Ip Range '123.123.123.23, 123.123.123.24'

        },
        validationSchema,
        onSubmit: values => {
            mutationCreateDevice.mutate(values);
        }
    });

    useEffect(() => {
        if (dataLocationsAndCnpj) {
            const locations = dataLocationsAndCnpj.locations.map(location => ({ value: location, title: location }))
            setLocations(locations);
            setCnpjs(dataLocationsAndCnpj.cnpjs.map(cnpj => ({ value: cnpj, title: cnpj })))
        }
    }, [dataLocationsAndCnpj]);

    useEffect(() => {
        if (dataEmployees?.data) {
            const newEmployees = dataEmployees.data.map(employee => ({
                value: employee.dados.matricula,
                title: `${employee.dados.matricula} | ${employee.dados.nome}`
            }));

            setEmployees(prevEmployees => [
                ...prevEmployees,
                ...newEmployees
            ]);
        }
    }, [dataEmployees]);

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight;
        if (bottom) {
            setOffset(offset + 1);
        }
    };

    function handleCreatedDevice() {
        setIsOpenCreateDevice(false);
        navigate('/');
    }
    
    return (
        <Container>
            <DeviceCreatedAlert isOpen={isOpenCreateDevice} onClose={handleCreatedDevice} />
            <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
                <Grid sx={gridContainerStyle} container spacing={10}>
                    <DeviceSection
                        formik={formik}
                        cnpjs={cnpjs}
                        deviceTypes={deviceTypes}
                        isLoadingLocationsAndCnpjs={isLoadingLocationsAndCnpjs} />

                    <AuthorizedCollaboratorsSection
                        formik={formik}
                        employees={employees}
                        locations={locations}
                        isLoadingLocationsAndCnpjs={isLoadingLocationsAndCnpjs}
                        handleScroll={handleScroll}
                        isLoadingEmployees={isLoadingEmployees}
                        filterTypes={filterTypes}

                    />

                    <ControllerSection formik={formik} />

                    <RestrictionSection formik={formik} />
                </Grid>
                <Divider style={{ marginBottom: 16 }} />
                <Button type="outlined"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate('/');
                    }}
                >{i18n.t('pages.newDevice.cancel')}</Button>
                <Button type="submit">{i18n.t('pages.newDevice.createDevice')}</Button>
            </form>
        </Container>
    );
};

export default CreateDeviceContent;

