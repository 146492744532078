import { FormControlLabel, styled } from '@mui/material';

export const CustomCheckbox = styled(FormControlLabel)({
    "& .MuiFormControlLabel-label" : {
        color: 'var(--ahg-waterloo-darken1)',
        fontFamily: 'Open Sans',
        fontSize: 14,
        "&.Mui-disabled": {
            color: 'var(--ahg-waterloo-lighten1)'
        }
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked" : {
        color: 'var(--ahg-primary-base)',
        "&.Mui-disabled": {
            color: 'var(--ahg-waterloo-lighten1)'
        }
    }
});