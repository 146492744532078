import React from 'react';
import { StyledTypography } from './styles';

const Typography = ({variant, display, children, sx }) => {
    return (
        <StyledTypography variant={variant} display={display} sx={sx}>
            {children}
        </StyledTypography>
    );
}

export default Typography;
