import React, { useEffect, useState } from 'react';
import { Helper, Divider } from './styles';
import { Container, Select, Switch, Button } from '../Layout';
import i18n from '../../helpers/i18n'; // Importando o i18n
import { multipleDeviceTypes } from '../../helpers/consts/deviceConstants';
import timeZones from '../../helpers/consts/timezones';
import { getLocationsAndCnpj, createMultipleDevices } from '../CreateDeviceContent/CreateDeviceContent.queries';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { validationMultipleSchema } from './validationMultipleSchema';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import MultipleDevicesCreatedAlert from '../MultipleDevicesCreatedAlert/';
import LoadingOverlay from '../LoadingOverlay';
import { useNavigate } from 'react-router-dom';


const MultipleDevicesContent = () => {
    const navigate = useNavigate();
    const [cnpjs, setCnpjs] = useState([]);
    const [isOpenConfirmAlert, setIsOpenConfirmAlert] = useState(false);
    const [devicesLenght, setDevicesLenght] = useState(0);
    const [showLoading, setShowLoading ] = useState(false);

    const { data: dataCnpjs } = useQuery({
        queryKey: ['locations'],
        queryFn: getLocationsAndCnpj,
        refetchOnWindowFocus: false,
        retry: false
    });

    const mutationCreateMultipleDevice = useMutation({
        mutationFn: createMultipleDevices,
        onSuccess: (response) => {
            setShowLoading(false);
            if (response.result) {
                setDevicesLenght(response.employees.length);
                setIsOpenConfirmAlert(true);
            } else {
                alert(`Não foi possivel criar os dispositivos, erro: ${response.errorType}`);
            }
        },
        onError: (e) => {
            alert('Falha ao criar multiplos dispositivos.', e);
        },
    });

    const formik = useFormik({
        initialValues: {
            deviceType: '',
            cnpj: '',
            timezone: '',
            activationKey: '',
            expirationDate: false,
            startPeriod: null,
            endPeriod: null,
        },
        validationSchema: validationMultipleSchema,
        onSubmit: values => {
            setShowLoading(true);
            mutationCreateMultipleDevice.mutate(values);
        }
    });


    useEffect(() => {
        if (dataCnpjs) {
            setCnpjs(dataCnpjs.cnpjs.map(cnpj => ({ value: cnpj, title: cnpj })))
        }
    }, [dataCnpjs]);

    function handleCreatedMultipleDevice () {
        setIsOpenConfirmAlert(false);
        navigate('/');
    }

    return (
        <Container>
            <LoadingOverlay isLoading={showLoading} />
            <MultipleDevicesCreatedAlert isOpen={isOpenConfirmAlert} onCreated={handleCreatedMultipleDevice} devicesLenght={devicesLenght} />
            <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
                <Divider style={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
                    <Helper elevation={0}>
                        <li>
                            <span style={{ margin: '0px 4px' }}></span>
                            {i18n.t('pages.multipleDevice.batidaOnlineExplanation')}
                        </li>
                        <li> <span style={{ margin: '0px 4px' }}></span>
                            {i18n.t('pages.multipleDevice.multiExplanation')}
                        </li>
                    </Helper>
                    <Select
                        items={multipleDeviceTypes}
                        id="device-type"
                        title={i18n.t('pages.multipleDevice.deviceType')}
                        value={formik.values.deviceType}
                        onChange={(event) => formik.setFieldValue('deviceType', event.target.value)}
                        onBlur={formik.handleBlur}
                        error={formik.touched.deviceType && Boolean(formik.errors.deviceType)}
                    />
                    <Select
                        items={cnpjs}
                        id="cnpj"
                        title="CNPJ"
                        value={formik.values.cnpj}
                        onChange={(event) => formik.setFieldValue('cnpj', event.target.value)}
                        onBlur={formik.handleBlur}
                        error={formik.touched.cnpj && Boolean(formik.errors.cnpj)}
                    />
                    <Select
                        items={timeZones}
                        id="fuso-horario"
                        title={i18n.t('pages.multipleDevice.timezone')}
                        value={formik.values.timezone}
                        onChange={(event) => formik.setFieldValue('timezone', event.target.value)}
                        onBlur={formik.handleBlur}
                        error={formik.touched.timezone && Boolean(formik.errors.timezone)}
                    />
                    <Switch
                        label={i18n.t('pages.multipleDevice.expirationDate')}
                        checked={formik.values.expirationDate}
                        onChange={(event) => formik.setFieldValue('expirationDate', event.target.checked)}
                    />
                    {formik.values.expirationDate && (
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'start' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    sx={{
                                        marginLeft: '16px !important',
                                        width: 'calc(100% - 32px)',
                                        fontSize: 12,
                                        '& .MuiInputBase-root': {
                                            flexDirection: 'row-reverse',
                                        },
                                        '& .MuiButtonBase-root': {
                                            color: '#2D9CDB',
                                        },
                                    }}
                                    slotProps={{ textField: { placeholder: i18n.t('filter.from') } }}
                                    value={formik.values.startePriod}
                                    onChange={(val) => formik.setFieldValue('startPeriod', val)}

                                />

                                <DatePicker
                                    format="DD/MM/YYYY"
                                    sx={{
                                        marginLeft: '16px !important',
                                        width: 'calc(100% - 32px)',
                                        fontSize: 12,
                                        '& .MuiInputBase-root': {
                                            flexDirection: 'row-reverse',
                                        },
                                        '& .MuiButtonBase-root': {
                                            color: '#2D9CDB',
                                        },
                                    }}
                                    slotProps={{ textField: { placeholder: i18n.t('filter.to') } }}
                                    value={formik.values.endPeriod}
                                    onChange={(val) => formik.setFieldValue('endPeriod', val)}
                                />
                            </LocalizationProvider>
                        </div>
                    )}
                    <br />
                    {/* <Checkbox label={i18n.t('pages.multipleDevice.duplicateDevice')} /> */}
                </Divider>
                <Divider></Divider>
                <Divider style={{ borderTop: 'solid 1px var(--ahg-gray-lighten4)', paddingTop: 16, marginTop: 16 }}>
                    <Button 
                        type="outlined"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/');
                        }}
                    
                    >{i18n.t('pages.multipleDevice.cancel')}</Button>
                    <Button 
                        type="submit"
                        >{i18n.t('pages.multipleDevice.createDevice')}
                    </Button>
                </Divider>
            </form>
        </Container>
    );
};

export default MultipleDevicesContent;
