import { styled } from '@mui/system';

export const ButtonStyled = styled('button')({
    border: '1px dashed var(--ahg-waterloo-darken1)',
    borderRadius: 14,
    minWidth: 120,
    height: 28,
    marginLeft: 16,
    backgroundColor: 'transparent',
    color: 'var(--ahg-waterloo-darken1)',
    fontWeight: 600,
    fontSize: 14,
    fontFamily: 'Open Sans',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    cursor: 'pointer',
    textTransform: 'none',
});

export const IconStyled = styled('span')({
    color: 'var(--ahg-waterloo-darken1)',
    display: 'flex'
});

export const MenuStyled = styled('div')({
    '& .MuiMenu-paper': {
        width: 360,
    },
});
