import { mkConfig, generateCsv, download } from 'export-to-csv';
import { saveAs } from 'file-saver';
import { write, utils } from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const verifyObject = (data) => {
  const hasDeepObject = data.map((item) => {
    return Object.keys(item).some(key => typeof item[key] === 'object' && !Array.isArray(item[key]));
  }).some(hasDeep => hasDeep === true);

  return hasDeepObject;
};

const flattenObj = (obj) => {
  if (!obj) return false;
  const result = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const temp = flattenObj(obj[key]);
      Object.keys(temp).forEach((nestedKey) => {
        result[`${key}.${nestedKey}`] = temp[nestedKey];
      });
    } else {
      result[key] = obj[key];
    }
  });
  return result;
};

const exportFile = (columnsTable, data, type = 'xlsx') => {
  const isNestedObject = verifyObject(data);
  const json = data.reduce((result, val) => {
    const rowFile = {};
    Object.entries(val).map(([key, value]) => {
      if (isNestedObject) {
        value = flattenObj(val);
        columnsTable.forEach((column) => {
          if (value[column.name] !== undefined) {
            rowFile[column.label] = value[column.name];
          }
        });
      } else {
        columnsTable.forEach((column) => {
          if (column.name === key) rowFile[column.label] = value;
        });
      }
      return key;
    });

    result.push(rowFile);
    return result;
  }, []);

  if (type === 'csv') {
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: 'tableDownloadCSV',
      useBom: true,
      columnHeaders: columnsTable.map(column => column.label),
    };
    const csvConfig = mkConfig(options);
    const csvData = generateCsv(csvConfig)(json);
    download(csvConfig)(csvData);
  } else if (type === 'pdf') {
    const doc = new jsPDF();
    const headers = columnsTable.map(column => column.label);
    const rows = json.map(row => columnsTable.map(column => row[column.label]));
    doc.autoTable({
      head: [headers],
      body: rows,
    });
    doc.save('tableDownloadPDF.pdf');
  } else {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'tableDownloadXLSX';
    const ws = utils.json_to_sheet(json);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const dataXLS = new Blob([excelBuffer], { type: fileType });
    saveAs(dataXLS, fileName + fileExtension);
  }
  return false;
};

export default exportFile;
