import React from 'react';
import { MenuItem } from '@mui/material';
import { CustomSelect, Label, Form } from './styles';

const Select = ({ ...props }) => {
    const handleLabelHover = (action) => {
        const el = document.getElementById(`${props.id}-label`);
        if(action === 'enter') {
            el.classList.add('input-hovered');
        } else {
            el.classList.remove('input-hovered');
        }
    }
    return (<Form>
            <Label id={`${props.id}-label`} disabled={props.disabled} >{props.title}</Label>
            <CustomSelect 
                id={props.id} 
                labelId={`${props.id}-label`} 
                onMouseEnter={() => handleLabelHover('enter')} 
                onMouseLeave={() => handleLabelHover('leave')} 
                disabled={props.disabled}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                error={props.error}
            >
                {
                    props.items.map(item => 
                        <MenuItem key={item.value} value={item.value}>
                            {item.title}
                        </MenuItem>
                    )
                }
            </CustomSelect>
        </Form>
    );
}

export default Select;