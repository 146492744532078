import React from 'react';
import { PrimaryButton, Modal, ModalTitle, ModalBody, ModalFooter, TextButton } from './styles';

const AlertModal = ({ 
    title = '', 
    children,
    cancelAction = null, 
    cancelText = '', 
    buttonAction = () => {}, 
    buttonText = '', 
    buttonColor = 'primary', 
    visible = false 
}) => {
    const RenderButton = () => {
        switch(buttonColor) {
            case 'outlined': 
                return (<></>);
            default:
                return (<PrimaryButton variant="contained" onClick={ buttonAction }> {buttonText} </PrimaryButton>);
        }
    };

    return (
        <Modal open={visible}>
            <ModalTitle>{ title }</ModalTitle>
            <ModalBody dividers>{ children } </ModalBody>
            <ModalFooter>
                { cancelAction && (
                    <TextButton onClick={ cancelAction }>{ cancelText }</TextButton>
                ) }
                <RenderButton />
            </ModalFooter>
        </Modal>
    );
};

export default AlertModal;