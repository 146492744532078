import React from 'react';
import { Link } from 'react-router-dom';


function DeviceName({ value , id}) {

    return (
        <Link to={`/edit/${id}`} style={{ color: '#1199DD' }}>
            {value}
        </Link>
    );

}

export default DeviceName;
