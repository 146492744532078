import styled from "styled-components";
import FilterListIcon from '@mui/icons-material/FilterList';

export const Content = styled.div`
    height: 52px;
    width: calc(100% - 32px);
    background-color: #fff;
    margin-left: 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    display: flex;
    color: var(--ahg-primary-base);
    align-items: center;
`;

export const FilterIcon = styled(FilterListIcon)`
    color: var(--ahg-primary-base);
    margin-left: 16px;
`;