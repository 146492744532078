import { styled } from '@mui/system';

export const TitleH3 = styled('h3')({
    color: 'var(--ahg-waterloo-darken1)',
    fontFamily: 'Fira Sans',
    fontSize: '18px',
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
});

export const TitleSpan = styled('span')({
    color: 'var(--ahg-waterloo-darken1)',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19.07px',
    textAlign: 'left',
});

export const checkboxStyle = {
 display: 'flex', 
 alignItems: 'center',
 padding: '6px 0 6px 16px'
}


export const gridContainerStyle = {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr 1fr',
    gridAutoColumns: 'auto',
    gridTemplateRows: 'repeat(2, 1fr)',
    gap: '10px 32px',
    width: '100%',
    marginTop: 0,
    marginLeft: 0,
};

export const gridItemStyle = {
    padding: 10,
    width: '100%',
    maxWidth: '100%',
};

export const gridItemWithPaddingStyle = {
    width: '100%',
    maxWidth: '100%',
    paddingTop: '20px',
};

export const dividerStyle = {
    borderTop: 'solid 1px var(--ahg-gray-lighten4)',
    paddingTop: 16,
    marginTop: 16,
};
