import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const StatusActive = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: 'normal',
    color: 'var(--ahg-green-base)'
});

export const StatusInactive = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: 'normal',
    color: 'var(--ahg-red-base)'
});

export const StatusWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    height: '50px'
    
})