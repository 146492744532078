import React from 'react';
import { Checkbox, Typography }  from '../Layout';
import InfoTooltip from '../InfoTooltip';
import { TitleH3, TitleSpan, gridItemStyle, checkboxStyle } from '../CreateDeviceContent/styles';
import i18n from '../../helpers/i18n';
import { Button, Grid, TextField } from '@mui/material';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';

function GeolocationFlag({ formik }) {
    return (
        <div style={checkboxStyle}>
            <Checkbox
                label={i18n.t('pages.newDevice.geolocation')}
                checked={formik.values.only_with_location}
                onChange={(event) => formik.setFieldValue('only_with_location', event.target.checked)}
            />
            <InfoTooltip tooltip={i18n.t('pages.newDevice.geolocationDescription')} />
        </div>
    );
}

function IpRangeFlag({ formik }) {

    const handleAddIp = () => {
        formik.setFieldValue('ips', [...formik.values.ips, '']);
    };

    const handleRemoveIp = (index) => {
        if (formik.values.ips.length > 1) {
            const updatedIps = formik.values.ips.filter((_, i) => i !== index);
            formik.setFieldValue('ips', updatedIps);
        }
    };

    const handleIpChange = (index, value) => {
        const updatedIps = [...formik.values.ips];
        updatedIps[index] = value;
        formik.setFieldValue('ips', updatedIps);
    };


    return (
        <>
        <div style={checkboxStyle}>
            <Checkbox
                label={i18n.t('pages.newDevice.ipRange')}
                checked={formik.values.range_ip}
                onChange={(event) => formik.setFieldValue('range_ip', event.target.checked)}
            />
            <InfoTooltip tooltip={i18n.t('pages.newDevice.ipRangeDescription')} />
        </div>

         {formik.values.range_ip && (
            <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%', justifyContent: 'start', paddingLeft: '40px'}}>
                {formik.values.ips.length === 0 && (
                    <div
                        style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}
                    >
                        <TextField
                            id={`ip-range-1`}
                            label={`Faixa 1`}
                            value={formik.values.ips[0]}
                            onChange={(event) => handleIpChange(0, event.target.value)}
                            sx={{ width: '50%' }}
                        />
                        <Button
                            variant="link"
                            color="primary"
                            onClick={handleAddIp}
                            style={{
                                borderRadius: '100%',
                                minWidth: '40px',
                                width: '40px',
                                height: '40px',
                                fontSize: '24px',
                                color: 'black',
                            }}
                        >
                            <Add />
                        </Button>
                    </div>
                )}

                {formik.values.ips.map((ip, index) => (
                    <div
                        key={index}
                        style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}
                    >
                        <TextField
                            id={`ip-range-${index}`}
                            label={`Faixa ${index + 1}`}
                            value={ip}
                            onChange={(event) => handleIpChange(index, event.target.value)}
                            sx={{ width: '50%' }}
                        />
                        <Button
                            variant="link"
                            color="primary"
                            onClick={handleAddIp}
                            style={{
                                borderRadius: '100%',
                                minWidth: '40px',
                                width: '40px',
                                height: '40px',
                                fontSize: '24px',
                                color: 'black',
                            }}
                        >
                            <Add />
                        </Button>

                        {index > 0 && (
                            <Button
                                variant="link"
                                color="primary"
                                onClick={() => handleRemoveIp(index)}
                                style={{
                                    borderRadius: '100%',
                                    minWidth: '40px',
                                    width: '40px',
                                    height: '40px',
                                    fontSize: '16px',
                                }}
                            >
                                <Close />
                            </Button>
                        )}
                    </div>
                ))}
                
            </div>
         </>)}
        </>
    );
}

function OnlineOnlyFlag({ formik }) {
    return (
        <div style={checkboxStyle}>
            <Checkbox
                label={i18n.t('pages.newDevice.networkConnection')}
                checked={formik.values.online_only}
                onChange={(event) => formik.setFieldValue('online_only', event.target.checked)}
            />
            <InfoTooltip tooltip={i18n.t('pages.newDevice.networkConnectionDescription')} />
        </div>
    );
}

function DateHourTimezoneAutoFlag({ formik }) {
    return (
        <div style={checkboxStyle}>
            <Checkbox
                label={i18n.t('pages.newDevice.dateHourTimezoneAuto')}
                checked={formik.values.auto_timezone}
                onChange={(event) => formik.setFieldValue('auto_timezone', event.target.checked)}
            />
            <InfoTooltip tooltip={i18n.t('pages.newDevice.dateHourTimezoneAutoDescription')} />
        </div>
    );
}

export default function RestrictionSection({formik}) {
    return (
        <>
            {formik.values.deviceType === 'ahgora_online_device' && (
                <Grid style={gridItemStyle} item xs={12} md={6}>
                    <div style={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
                        <TitleH3>
                            {i18n.t('pages.newDevice.restriction')} <TitleSpan>{i18n.t('pages.newDevice.optional')}</TitleSpan>
                        </TitleH3>
                    </div>
                    <Typography variant="body2">{i18n.t('pages.newDevice.restrictionSubtitle')}</Typography>
                    <GeolocationFlag formik={formik} />
                    <IpRangeFlag formik={formik} />
                </Grid>
            )}
            {['ahgora_multi_device', 'biometry_device'].includes(formik.values.deviceType) && (
                <Grid style={gridItemStyle} item xs={12} md={6}>
                    <div style={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
                        <TitleH3>
                            {i18n.t('pages.newDevice.restriction')} <TitleSpan>{i18n.t('pages.newDevice.optional')}</TitleSpan>
                        </TitleH3>
                    </div>
                    <Typography variant="body2">{i18n.t('pages.newDevice.restrictionSubtitle')}</Typography>
                    <OnlineOnlyFlag formik={formik} />
                    <DateHourTimezoneAutoFlag formik={formik} />
                    <GeolocationFlag formik={formik} />
                    <IpRangeFlag formik={formik} />
                </Grid>
            )}
            {formik.values.deviceType === 'my_ahgora_device' && (
                <Grid style={gridItemStyle} item xs={12} md={6}>
                    <div style={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
                        <TitleH3>
                            {i18n.t('pages.newDevice.restriction')} <TitleSpan>{i18n.t('pages.newDevice.optional')}</TitleSpan>
                        </TitleH3>
                    </div>
                    <Typography variant="body2">{i18n.t('pages.newDevice.restrictionSubtitle')}</Typography>
                    <GeolocationFlag formik={formik} />
                </Grid>
            )}
        </>
    );
}
