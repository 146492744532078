import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { datePickerStyles } from './styles';
import i18n from '../../helpers/i18n';

const FilterDate = ({ filterDate, setFilterDate }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                format="DD/MM/YYYY"
                sx={datePickerStyles}
                slotProps={{ textField: { placeholder: i18n.t('filter.from') } }}
                value={filterDate.start}
                onChange={(val) => setFilterDate({ ...filterDate, start: val })}
            />

            <DatePicker
                format="DD/MM/YYYY"
                sx={datePickerStyles}
                slotProps={{ textField: { placeholder: i18n.t('filter.to') } }}
                value={filterDate.end}
                onChange={(val) => setFilterDate({ ...filterDate, end: val })}
            />
        </LocalizationProvider>

    );
};

export default FilterDate;
