import React from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';

import { CustomCheckbox } from './styles';

const Checkbox = ({ checked, ...props }) => {
    return (
        <CustomCheckbox
            disabled={props.disabled}
            control={ <MuiCheckbox checked={props.checked} /> } 
            label={ props.label }
            onChange={ props.onChange }
            sx={props.sx}
            checked={checked}
        />
    );
};

export default Checkbox;