import i18n from '../../helpers/i18n';


export const deviceTypes = [
    { value: 'ahgora_online_device', title: i18n.t('pages.newDevice.ahgoraOnlineDevice') },
    { value: 'ahgora_multi_device', title: i18n.t('pages.newDevice.ahgoraMultiDevice') },
    { value: 'my_ahgora_device', title: i18n.t('pages.newDevice.my_ahgora') },
];

export const multipleDeviceTypes = [
    { value: 'ahgora_online_device', title: i18n.t('pages.newDevice.ahgoraOnlineDevice') },
    { value: 'ahgora_multi_device', title: i18n.t('pages.newDevice.ahgoraMultiDevice') },
];

export const filterTypes = [
    { value: 'by_employee', title: i18n.t('pages.newDevice.byEmployee') },
    { value: 'by_location', title: i18n.t('pages.newDevice.byLocation') },
];

