import React from 'react';
import { Container } from './styles';
import Header from '../../components/Header/Header';
import DeviceTable from '../../components/DeviceTable/DeviceTable';
import Alert from '../../components/Alert';
import FilterBar from '../../components/FilterBar';
import useFilter from './hooks/useFilter';
import i18n from '../../helpers/i18n';

const HomePage = () => {
    const title = i18n.t("home.title");
    const breadCrumbs = [{ title }];
    const message = i18n.t("home.feedbackMsg");

    const filters = useFilter();

    return (
        <Container>
            <Header title={title} breadCrumbs={breadCrumbs} showButtons={true}/>
            <Alert alertMessage={message} />
            <FilterBar filters={filters} />
            <DeviceTable filters={filters} />
        </Container>
    );
}

export default HomePage;
