import { Select, styled, InputLabel, FormControl } from '@mui/material';

export const CustomSelect = styled(Select)({
    width: '100%',
    borderBottom: 'none',
    border: 'solid 1px var(--ahg-waterloo-lighten1)',
    borderRadius: 4,
    marginTop: 4,
    boxShadow: 'none',
    padding: '12px 8px 0px 16px',
    fontFamily: 'Open Sans',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    zIndex: 1,
    fontSize: '14px',
    "& .MuiSelect-icon": {
        right: 12
    },
    "&:not(.Mui-disabled)": {
        "&:hover,&:active,&:focus": {
            borderBottom: 'none',
            border: 'solid 1px var(--ahg-primary-base)',
            color: 'var(--ahg-primary-base)',
            "&.MuiInput-underline:before": {
                borderBottom: 'solid 1px var(--ahg-primary-base)'
            },
            "&.MuiInput-underline:after": {
                borderBottom: 'solid 1px var(--ahg-primary-base)'
            },
            "& .MuiSelect-icon": {
                color: 'var(--ahg-primary-base)'
            }
        }
    },
    "&:focus, .MuiSelect-select:focus": {
        border: 'none',
        backgroundColor: '#fff'
    },
    "& .MuiInput-underline:before": {
        borderBottom: 'solid 1px var(--ahg-waterloo-lighten1)'
    },
    "& .MuiInput-underline:after": {
        borderBottom: 'solid 1px var(--ahg-waterloo-lighten1)'
    },
    "& .Mui-disabled,&.Mui-disabled:hover": {
        color: 'var(--ahg-waterloo-lighten3)',
        borderColor: 'var(--ahg-waterloo-lighten3)',
        borderBottom: 'solid 1px var(--ahg-waterloo-lighten3)',
        boxShadow: 'none',
        "&.MuiInput-underline:before": {
            content: 'none'
        },
        
    },
    "& .MuiSelect-select": {
        padding: '16px 14px'
    }
});

export const Label = styled(InputLabel)({
    backgroundColor: '#fff !important',
    padding: '8px 10px 8px 16px',
    fontSize: '14px',
    color: 'var(--ahg-waterloo-darken1)',
    "&.MuiFormLabel-filled,&.Mui-focused": {
        marginTop: 30,
        marginLeft: 8,
        padding: 0,
        zIndex: 2,
        paddingLeft: 12,
        color: 'var(--ahg-waterloo-darken1)'
    },
    "&.input-hovered": {
        color: 'var(--ahg-primary-base)'
    },
    "&.Mui-disabled": {
        color: 'var(--ahg-waterloo-lighten3)',
    }
});

export const Form = styled(FormControl)({
    width: '100%',
    marginTop: 0,
    marginBottom: 8,
});