import React from 'react';
import { AlertFeedback } from './styles';

const Alert = ({ alertMessage }) => {
    return (
        <AlertFeedback severity="info" icon={false}>
            {alertMessage}
        </AlertFeedback>
    );
};

export default Alert;
