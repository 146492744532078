
import dayjs from 'dayjs';
import api from '../../api';
import consts from '../../helpers/consts';

function mapperDeviceData(deviceData) {
  let startPeriodFormated = ''
  let endPeriodFormated = ''
  if (deviceData.expirationDate) {
    startPeriodFormated = dayjs(deviceData.startPeriod).format('YYYY-MM-DD');
    endPeriodFormated = dayjs(deviceData.endPeriod).format('YYYY-MM-DD');
  }

  return {
    active: deviceData.active || false,
    name: deviceData.name,
    timezone: deviceData.timezone,
    device_type: deviceData.deviceType,
    startPeriod: startPeriodFormated,
    endPeriod: endPeriodFormated,
    activationKey: deviceData.activationKey | '',
    enable_password_punch: deviceData.enable_password_punch ? '1' : '0',
    // save_photo_on_punch	?
    timezone_filter: deviceData.timezone_filter ? '1' : '0',
    break_device: deviceData.break_device ? '1' : '0',
    enable_auto_update: deviceData.enable_auto_update ? '1' : '0',
    default_computer: deviceData.default_computer ? '1' : '0',
    triple_validation: deviceData.triple_validation ? 'on' : 'off',
    enable_punches_logs: deviceData.enable_punches_logs ? '1' : '0',
    enable_punch_backup: deviceData.enable_punch_backup ? '1' : '0',
    online_only: deviceData.online_only ? '1' : '0',
    auto_timezone: deviceData.auto_timezone ? '1' : '0',
    only_with_location: deviceData.only_with_location ? '1' : '0',
    range_ip: deviceData.ips.join(', '),

    optionEmployeesFilter: deviceData.collaboratorsType === 'by_employee' ? '1' : '0',

    cnpj: deviceData.cnpj,
    employees: deviceData.employees,
    locations: deviceData.locations,
  }
}


function mapperMultipleDeviceData(deviceData) {
  let startPeriodFormated = ''
  let endPeriodFormated = ''
  if (deviceData.expirationDate) {
    startPeriodFormated = dayjs(deviceData.startPeriod).format('YYYY-MM-DD');
    endPeriodFormated = dayjs(deviceData.endPeriod).format('YYYY-MM-DD');
  }

  return {
    timezone_device: deviceData.timezone,
    device_type: deviceData.deviceType,
    startPeriodMultiple: startPeriodFormated,
    endPeriodMultiple: endPeriodFormated,
    cnpj: deviceData.cnpj,
  }
}


export const createDevice = async (deviceData) => {
  const payload = mapperDeviceData(deviceData);
  try {
    const response = await api.post(`${consts.BASE_PATH_API}batidaonline/setRegistration`, payload);
    if (!response) {
      throw new Error('Não foi possível criar o dispositivo');
    }
    return {
      response
    };
  } catch (error) {
    throw new Error(`Erro ao criar dispositivos: ${error.message}`);
  }
}

export const createMultipleDevices = async (deviceData) => {
  const payload = mapperMultipleDeviceData(deviceData);
  try {
    const response = await api.post(`${consts.BASE_PATH_API}batidaonline/generateMultiActivationCodes`, payload);
    if (!response) {
      throw new Error('Não foi possível criar multiplos dispositivos');
    }
    return {
      ...response
    };
  } catch (error) {
    throw new Error(`Erro ao criar multiplos dispositivos: ${error.message}`);
  }
}

export const editDevice = async ({ identity, deviceData }) => {
  const payload = mapperDeviceData(deviceData);
  try {
    const response = await api.post(`${consts.BASE_PATH_API}batidaonline/setRegistration`, { ...payload, identity });
    if (!response) {
      throw new Error('Não foi possível criar o dispositivo');
    }
    return {
      response
    };
  } catch (error) {
    throw new Error(`Erro ao criar dispositivos: ${error.message}`);
  }
}



export const getEmployees = async ({ limit, offset }) => {
  try {
    const response = await api.get(`${consts.BASE_PATH_API}api/funcionarios?limit=${limit}&offset=${offset}&status=true&employeesByUser=true&filter[sort]=a-z&byName=`);
    if (response.error) {
      throw new Error('Não foi possível carregar os funcionários');
    }
    return {
      data: response.data
    };
  } catch (error) {
    throw new Error(`Erro ao carregar os funcionários: ${error.message}`);
  }
}

export const getLocationsAndCnpj = async () => {
  try {
    const response = await api.get(`${consts.BASE_PATH_API}api/empresas/getDevicesPermissions`);
    if (response.error) {
      throw new Error('Não foi possível carregar os funcionários');
    }
    return {
      locations: response.data.locations,
      cnpjs: response.data.cnpj
    };
  } catch (error) {
    throw new Error(`Erro ao carregar os funcionários: ${error.message}`);
  }
}

export const getDevice = async ({ identity }) => {
  try {
    const response = await api.get(`${consts.BASE_PATH_API}batidaonline/getRegistration?identity=${identity}`);
    if (response.error) {
      throw new Error('Não foi possível carregar o dispositivo');
    }
    return { ...response, name: response.name.replace(/&nbsp;/g, "").replace(/&nbsp/g, "") };

  } catch (error) {
    throw new Error(`Erro ao carregar dispositivo: ${error.message}`);
  }
}

