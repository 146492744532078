import AlertModal from '../AlertModal';



export default function DeviceCreatedAlert({ isOpen , onClose}) {
    return (
        <AlertModal
            title="Dispositivo criado"
            buttonText='Ok, Continuar'
            children={`Parabéns! O dispositivo foi criado com sucesso.`}
            buttonAction={onClose}
            visible={isOpen} />
    )
}