
import consts from '../../helpers/consts';

const endpoints =  { 
    getDevices: `${consts.BASE_PATH_API}batidaonline/makeTable`,
    
    getPermissions: `${consts.BASE_PATH_API}api/empresas/getDevicesPermissions`,

    getAfd: `${consts.BASE_PATH_API}batidaonline/afd/`
};
export default endpoints;

