import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import CreateEditPage from '../pages/CreateEditPage';

const Router = () => {
    return (
        <Routes>
            <Route element={<HomePage />} path="/" exact />
            <Route element={<CreateEditPage />} path="/edit/:id" exact />
            <Route element={<CreateEditPage />} path="/create" exact />
            <Route element={<CreateEditPage />} path="/multiple" exact />
        </Routes>
    );
};

export default Router;