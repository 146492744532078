const timezones = [
    { value: "America/Noronha", title: "America, Noronha (GMT-2)" },
    { value: "America/Sao_Paulo", title: "America, Sao Paulo (GMT-3)" },
    { value: "America/Recife", title: "America, Recife (GMT-3)" },
    { value: "America/Manaus", title: "America, Manaus (GMT-4)" },
    { value: "America/Rio_Branco", title: "America, Rio Branco (GMT-5)" },
    { value: "Africa/Abidjan", title: "Africa, Abidjan (GMT 0)" },
    { value: "Africa/Accra", title: "Africa, Accra (GMT 0)" },
    { value: "Africa/Addis_Ababa", title: "Africa, Addis Ababa (GMT +3)" },
    { value: "Africa/Algiers", title: "Africa, Algiers (GMT +1)" },
    { value: "Africa/Asmara", title: "Africa, Asmara (GMT +3)" },
    { value: "Africa/Bamako", title: "Africa, Bamako (GMT 0)" },
    { value: "Africa/Bangui", title: "Africa, Bangui (GMT +1)" },
    { value: "Africa/Banjul", title: "Africa, Banjul (GMT 0)" },
    { value: "Africa/Bissau", title: "Africa, Bissau (GMT 0)" },
    { value: "Africa/Blantyre", title: "Africa, Blantyre (GMT +2)" },
    { value: "Africa/Brazzaville", title: "Africa, Brazzaville (GMT +1)" },
    { value: "Africa/Bujumbura", title: "Africa, Bujumbura (GMT +2)" },
    { value: "Africa/Cairo", title: "Africa, Cairo (GMT +3)" },
    { value: "Africa/Casablanca", title: "Africa, Casablanca (GMT +1)" },
    { value: "Africa/Ceuta", title: "Africa, Ceuta (GMT +2)" },
    { value: "Africa/Conakry", title: "Africa, Conakry (GMT 0)" },
    { value: "Africa/Dakar", title: "Africa, Dakar (GMT 0)" },
    { value: "Africa/Dar_es_Salaam", title: "Africa, Dar es Salaam (GMT +3)" },
    { value: "Africa/Djibouti", title: "Africa, Djibouti (GMT +3)" },
    { value: "Africa/Douala", title: "Africa, Douala (GMT +1)" },
    { value: "Africa/El_Aaiun", title: "Africa, El Aaiun (GMT +1)" },
    { value: "Africa/Freetown", title: "Africa, Freetown (GMT 0)" },
    { value: "Africa/Gaborone", title: "Africa, Gaborone (GMT +2)" },
    { value: "Africa/Harare", title: "Africa, Harare (GMT +2)" },
    { value: "Africa/Johannesburg", title: "Africa, Johannesburg (GMT +2)" },
    { value: "Africa/Juba", title: "Africa, Juba (GMT +2)" },
    { value: "Africa/Kampala", title: "Africa, Kampala (GMT +3)" },
    { value: "Africa/Khartoum", title: "Africa, Khartoum (GMT +2)" },
    { value: "Africa/Kigali", title: "Africa, Kigali (GMT +2)" },
    { value: "Africa/Kinshasa", title: "Africa, Kinshasa (GMT +1)" },
    { value: "Africa/Lagos", title: "Africa, Lagos (GMT +1)" },
    { value: "Africa/Libreville", title: "Africa, Libreville (GMT +1)" },
    { value: "Africa/Lome", title: "Africa, Lome (GMT 0)" },
    { value: "Africa/Luanda", title: "Africa, Luanda (GMT +1)" },
    { value: "Africa/Lubumbashi", title: "Africa, Lubumbashi (GMT +2)" },
    { value: "Africa/Lusaka", title: "Africa, Lusaka (GMT +2)" },
    { value: "Africa/Malabo", title: "Africa, Malabo (GMT +1)" },
    { value: "Africa/Maputo", title: "Africa, Maputo (GMT +2)" },
    { value: "Africa/Maseru", title: "Africa, Maseru (GMT +2)" },
    { value: "Africa/Mbabane", title: "Africa, Mbabane (GMT +2)" },
    { value: "Africa/Mogadishu", title: "Africa, Mogadishu (GMT +3)" },
    { value: "Africa/Monrovia", title: "Africa, Monrovia (GMT 0)" },
    { value: "Africa/Nairobi", title: "Africa, Nairobi (GMT +3)" },
    { value: "Africa/Ndjamena", title: "Africa, Ndjamena (GMT +1)" },
    { value: "Africa/Niamey", title: "Africa, Niamey (GMT +1)" },
    { value: "Africa/Nouakchott", title: "Africa, Nouakchott (GMT 0)" },
    { value: "Africa/Ouagadougou", title: "Africa, Ouagadougou (GMT 0)" },
    { value: "Africa/Porto-Novo", title: "Africa, Porto-Novo (GMT +1)" },
    { value: "Africa/Sao_Tome", title: "Africa, Sao Tome (GMT 0)" },
    { value: "Africa/Tripoli", title: "Africa, Tripoli (GMT +2)" },
    { value: "Africa/Tunis", title: "Africa, Tunis (GMT +1)" },
    { value: "Africa/Windhoek", title: "Africa, Windhoek (GMT +2)" },
    { value: "America/Adak", title: "America, Adak (GMT -9)" },
    { value: "America/Anchorage", title: "America, Anchorage (GMT -8)" },
    { value: "America/Anguilla", title: "America, Anguilla (GMT -4)" },
    { value: "America/Antigua", title: "America, Antigua (GMT -4)" },
    { value: "America/Araguaina", title: "America, Araguaina (GMT -3)" },
    { value: "America/Argentina/Buenos_Aires", title: "America, Argentina, Buenos Aires (GMT -3)" },
    { value: "America/Argentina/Catamarca", title: "America, Argentina, Catamarca (GMT -3)" },
    { value: "America/Argentina/Cordoba", title: "America, Argentina, Cordoba (GMT -3)" },
    { value: "America/Argentina/Jujuy", title: "America, Argentina, Jujuy (GMT -3)" },
    { value: "America/Argentina/La_Rioja", title: "America, Argentina, La Rioja (GMT -3)" },
    { value: "America/Argentina/Mendoza", title: "America, Argentina, Mendoza (GMT -3)" },
    { value: "America/Argentina/Rio_Gallegos", title: "America, Argentina, Rio Gallegos (GMT -3)" },
    { value: "America/Argentina/Salta", title: "America, Argentina, Salta (GMT -3)" },
    { value: "America/Argentina/San_Juan", title: "America, Argentina, San Juan (GMT -3)" },
    { value: "America/Argentina/San_Luis", title: "America, Argentina, San Luis (GMT -3)" },
    { value: "America/Argentina/Tucuman", title: "America, Argentina, Tucuman (GMT -3)" },
    { value: "America/Argentina/Ushuaia", title: "America, Argentina, Ushuaia (GMT -3)" },
    { value: "America/Aruba", title: "America, Aruba (GMT -4)" },
    { value: "America/Asuncion", title: "America, Asuncion (GMT -4)" },
    { value: "America/Atikokan", title: "America, Atikokan (GMT -5)" },
    { value: "America/Bahia", title: "America, Bahia (GMT -3)" },
    { value: "America/Bahia_Banderas", title: "America, Bahia Banderas (GMT -6)" },
    { value: "America/Barbados", title: "America, Barbados (GMT -4)" },
    { value: "America/Belem", title: "America, Belem (GMT -3)" },
    { value: "America/Belize", title: "America, Belize (GMT -6)" },
    { value: "America/Blanc-Sablon", title: "America, Blanc-Sablon (GMT -4)" },
    { value: "America/Boa_Vista", title: "America, Boa Vista (GMT -4)" },
    { value: "America/Bogota", title: "America, Bogota (GMT -5)" },
    { value: "America/Boise", title: "America, Boise (GMT -6)" },
    { value: "America/Cambridge_Bay", title: "America, Cambridge Bay (GMT -6)" },
    { value: "America/Campo_Grande", title: "America, Campo Grande (GMT -4)" },
    { value: "America/Cancun", title: "America, Cancun (GMT -5)" },
    { value: "America/Caracas", title: "America, Caracas (GMT -4)" },
    { value: "America/Cayenne", title: "America, Cayenne (GMT -3)" },
    { value: "America/Cayman", title: "America, Cayman (GMT -5)" },
    { value: "America/Chicago", title: "America, Chicago (GMT -5)" },
    { value: "America/Chihuahua", title: "America, Chihuahua (GMT -6)" },
    { value: "America/Ciudad_Juarez", title: "America, Ciudad Juarez (GMT -6)" },
    { value: "America/Costa_Rica", title: "America, Costa Rica (GMT -6)" },
    { value: "America/Creston", title: "America, Creston (GMT -7)" },
    { value: "America/Cuiaba", title: "America, Cuiaba (GMT -4)" },
    { value: "America/Curacao", title: "America, Curacao (GMT -4)" },
    { value: "America/Danmarkshavn", title: "America, Danmarkshavn (GMT 0)" },
    { value: "America/Dawson", title: "America, Dawson (GMT -7)" },
    { value: "America/Dawson_Creek", title: "America, Dawson Creek (GMT -7)" },
    { value: "America/Denver", title: "America, Denver (GMT -6)" },
    { value: "America/Detroit", title: "America, Detroit (GMT -4)" },
    { value: "America/Dominica", title: "America, Dominica (GMT -4)" },
    { value: "America/Edmonton", title: "America, Edmonton (GMT -6)" },
    { value: "America/Eirunepe", title: "America, Eirunepe (GMT -5)" },
    { value: "America/El_Salvador", title: "America, El Salvador (GMT -6)" },
    { value: "America/Fort_Nelson", title: "America, Fort Nelson (GMT -7)" },
    { value: "America/Fortaleza", title: "America, Fortaleza (GMT -3)" },
    { value: "America/Glace_Bay", title: "America, Glace Bay (GMT -3)" },
    { value: "America/Goose_Bay", title: "America, Goose Bay (GMT -3)" },
    { value: "America/Grand_Turk", title: "America, Grand Turk (GMT -4)" },
    { value: "America/Grenada", title: "America, Grenada (GMT -4)" },
    { value: "America/Guadeloupe", title: "America, Guadeloupe (GMT -4)" },
    { value: "America/Guatemala", title: "America, Guatemala (GMT -6)" },
    { value: "America/Guayaquil", title: "America, Guayaquil (GMT -5)" },
    { value: "America/Guyana", title: "America, Guyana (GMT -4)" },
    { value: "America/Halifax", title: "America, Halifax (GMT -3)" },
    { value: "America/Havana", title: "America, Havana (GMT -4)" },
    { value: "America/Hermosillo", title: "America, Hermosillo (GMT -7)" },
    { value: "America/Indiana/Indianapolis", title: "America, Indiana, Indianapolis (GMT -4)" },
    { value: "America/Indiana/Knox", title: "America, Indiana, Knox (GMT -5)" },
    { value: "America/Indiana/Marengo", title: "America, Indiana, Marengo (GMT -4)" },
    { value: "America/Indiana/Petersburg", title: "America, Indiana, Petersburg (GMT -4)" },
    { value: "America/Indiana/Tell_City", title: "America, Indiana, Tell City (GMT -5)" },
    { value: "America/Indiana/Vevay", title: "America, Indiana, Vevay (GMT -4)" },
    { value: "America/Indiana/Vincennes", title: "America, Indiana, Vincennes (GMT -4)" },
    { value: "America/Indiana/Winamac", title: "America, Indiana, Winamac (GMT -4)" },
    { value: "America/Inuvik", title: "America, Inuvik (GMT -6)" },
    { value: "America/Iqaluit", title: "America, Iqaluit (GMT -4)" },
    { value: "America/Jamaica", title: "America, Jamaica (GMT -5)" },
    { value: "America/Juneau", title: "America, Juneau (GMT -8)" },
    { value: "America/Kentucky/Louisville", title: "America, Kentucky, Louisville (GMT -4)" },
    { value: "America/Kentucky/Monticello", title: "America, Kentucky, Monticello (GMT -4)" },
    { value: "America/Kralendijk", title: "America, Kralendijk (GMT -4)" },
    { value: "America/La_Paz", title: "America, La Paz (GMT -4)" },
    { value: "America/Lima", title: "America, Lima (GMT -5)" },
    { value: "America/Los_Angeles", title: "America, Los Angeles (GMT -7)" },
    { value: "America/Lower_Princes", title: "America, Lower Princes (GMT -4)" },
    { value: "America/Maceio", title: "America, Maceio (GMT -3)" },
    { value: "America/Managua", title: "America, Managua (GMT -6)" },
    { value: "America/Marigot", title: "America, Marigot (GMT -4)" },
    { value: "America/Martinique", title: "America, Martinique (GMT -4)" },
    { value: "America/Matamoros", title: "America, Matamoros (GMT -5)" },
    { value: "America/Mazatlan", title: "America, Mazatlan (GMT -7)" },
    { value: "America/Menominee", title: "America, Menominee (GMT -5)" },
    { value: "America/Merida", title: "America, Merida (GMT -6)" },
    { value: "America/Metlakatla", title: "America, Metlakatla (GMT -8)" },
    { value: "America/Mexico_City", title: "America, Mexico City (GMT -6)" },
    { value: "America/Miquelon", title: "America, Miquelon (GMT -2)" },
    { value: "America/Moncton", title: "America, Moncton (GMT -3)" },
    { value: "America/Monterrey", title: "America, Monterrey (GMT -6)" },
    { value: "America/Montevideo", title: "America, Montevideo (GMT -3)" },
    { value: "America/Montserrat", title: "America, Montserrat (GMT -4)" },
    { value: "America/Nassau", title: "America, Nassau (GMT -4)" },
    { value: "America/New_York", title: "America, New York (GMT -4)" },
    { value: "America/Nome", title: "America, Nome (GMT -8)" },
    { value: "America/North_Dakota/Beulah", title: "America, North Dakota, Beulah (GMT -5)" },
    { value: "America/North_Dakota/Center", title: "America, North Dakota, Center (GMT -5)" },
    { value: "America/North_Dakota/New_Salem", title: "America, North Dakota, New Salem (GMT -5)" },
    { value: "America/Nuuk", title: "America, Nuuk (GMT -1)" },
    { value: "America/Ojinaga", title: "America, Ojinaga (GMT -5)" },
    { value: "America/Panama", title: "America, Panama (GMT -5)" },
    { value: "America/Paramaribo", title: "America, Paramaribo (GMT -3)" },
    { value: "America/Phoenix", title: "America, Phoenix (GMT -7)" },
    { value: "America/Port-au-Prince", title: "America, Port-au-Prince (GMT -4)" },
    { value: "America/Port_of_Spain", title: "America, Port of Spain (GMT -4)" },
    { value: "America/Porto_Velho", title: "America, Porto Velho (GMT -4)" },
    { value: "America/Puerto_Rico", title: "America, Puerto Rico (GMT -4)" },
    { value: "America/Punta_Arenas", title: "America, Punta Arenas (GMT -3)" },
    { value: "America/Rankin_Inlet", title: "America, Rankin Inlet (GMT -5)" },
    { value: "America/Regina", title: "America, Regina (GMT -6)" },
    { value: "America/Resolute", title: "America, Resolute (GMT -5)" },
    { value: "America/Santarem", title: "America, Santarem (GMT -3)" },
    { value: "America/Santiago", title: "America, Santiago (GMT -4)" },
    { value: "America/Santo_Domingo", title: "America, Santo Domingo (GMT -4)" },
    { value: "America/Scoresbysund", title: "America, Scoresbysund (GMT 0)" },
    { value: "America/Sitka", title: "America, Sitka (GMT -8)" },
    { value: "America/St_Barthelemy", title: "America, St Barthelemy (GMT -4)" },
    { value: "America/St_Johns", title: "America, St Johns (GMT -2,5)" },
    { value: "America/St_Kitts", title: "America, St Kitts (GMT -4)" },
    { value: "America/St_Lucia", title: "America, St Lucia (GMT -4)" },
    { value: "America/St_Thomas", title: "America, St Thomas (GMT -4)" },
    { value: "America/St_Vincent", title: "America, St Vincent (GMT -4)" },
    { value: "America/Swift_Current", title: "America, Swift Current (GMT -6)" },
    { value: "America/Tegucigalpa", title: "America, Tegucigalpa (GMT -6)" },
    { value: "America/Thule", title: "America, Thule (GMT -3)" },
    { value: "America/Tijuana", title: "America, Tijuana (GMT -7)" },
    { value: "America/Toronto", title: "America, Toronto (GMT -4)" },
    { value: "America/Tortola", title: "America, Tortola (GMT -4)" },
    { value: "America/Vancouver", title: "America, Vancouver (GMT -7)" },
    { value: "America/Whitehorse", title: "America, Whitehorse (GMT -7)" },
    { value: "America/Winnipeg", title: "America, Winnipeg (GMT -5)" },
    { value: "America/Yakutat", title: "America, Yakutat (GMT -8)" },
    { value: "Antarctica/Casey", title: "Antarctica, Casey (GMT +11)" },
    { value: "Antarctica/Davis", title: "Antarctica, Davis (GMT +7)" },
    { value: "Antarctica/DumontDUrville", title: "Antarctica, DumontDUrville (GMT +10)" },
    { value: "Antarctica/Macquarie", title: "Antarctica, Macquarie (GMT +10)" },
    { value: "Antarctica/Mawson", title: "Antarctica, Mawson (GMT +5)" },
    { value: "Antarctica/McMurdo", title: "Antarctica, McMurdo (GMT +12)" },
    { value: "Antarctica/Palmer", title: "Antarctica, Palmer (GMT -3)" },
    { value: "Antarctica/Rothera", title: "Antarctica, Rothera (GMT -3)" },
    { value: "Antarctica/Syowa", title: "Antarctica, Syowa (GMT +3)" },
    { value: "Antarctica/Troll", title: "Antarctica, Troll (GMT +2)" },
    { value: "Antarctica/Vostok", title: "Antarctica, Vostok (GMT +6)" },
    { value: "Arctic/Longyearbyen", title: "Arctic, Longyearbyen (GMT +2)" },
    { value: "Asia/Aden", title: "Asia, Aden (GMT +3)" },
    { value: "Asia/Almaty", title: "Asia, Almaty (GMT +6)" },
    { value: "Asia/Amman", title: "Asia, Amman (GMT +3)" },
    { value: "Asia/Anadyr", title: "Asia, Anadyr (GMT +12)" },
    { value: "Asia/Aqtau", title: "Asia, Aqtau (GMT +5)" },
    { value: "Asia/Aqtobe", title: "Asia, Aqtobe (GMT +5)" },
    { value: "Asia/Ashgabat", title: "Asia, Ashgabat (GMT +5)" },
    { value: "Asia/Atyrau", title: "Asia, Atyrau (GMT +5)" },
    { value: "Asia/Baghdad", title: "Asia, Baghdad (GMT +3)" },
    { value: "Asia/Bahrain", title: "Asia, Bahrain (GMT +3)" },
    { value: "Asia/Baku", title: "Asia, Baku (GMT +4)" },
    { value: "Asia/Bangkok", title: "Asia, Bangkok (GMT +7)" },
    { value: "Asia/Barnaul", title: "Asia, Barnaul (GMT +7)" },
    { value: "Asia/Beirut", title: "Asia, Beirut (GMT +3)" },
    { value: "Asia/Bishkek", title: "Asia, Bishkek (GMT +6)" },
    { value: "Asia/Brunei", title: "Asia, Brunei (GMT +8)" },
    { value: "Asia/Chita", title: "Asia, Chita (GMT +9)" },
    { value: "Asia/Choibalsan", title: "Asia, Choibalsan (GMT +8)" },
    { value: "Asia/Colombo", title: "Asia, Colombo (GMT +5,5)" },
    { value: "Asia/Damascus", title: "Asia, Damascus (GMT +3)" },
    { value: "Asia/Dhaka", title: "Asia, Dhaka (GMT +6)" },
    { value: "Asia/Dili", title: "Asia, Dili (GMT +9)" },
    { value: "Asia/Dubai", title: "Asia, Dubai (GMT +4)" },
    { value: "Asia/Dushanbe", title: "Asia, Dushanbe (GMT +5)" },
    { value: "Asia/Famagusta", title: "Asia, Famagusta (GMT +3)" },
    { value: "Asia/Gaza", title: "Asia, Gaza (GMT +3)" },
    { value: "Asia/Hebron", title: "Asia, Hebron (GMT +3)" },
    { value: "Asia/Ho_Chi_Minh", title: "Asia, Ho Chi Minh (GMT +7)" },
    { value: "Asia/Hong_Kong", title: "Asia, Hong Kong (GMT +8)" },
    { value: "Asia/Hovd", title: "Asia, Hovd (GMT +7)" },
    { value: "Asia/Irkutsk", title: "Asia, Irkutsk (GMT +8)" },
    { value: "Asia/Jakarta", title: "Asia, Jakarta (GMT +7)" },
    { value: "Asia/Jayapura", title: "Asia, Jayapura (GMT +9)" },
    { value: "Asia/Jerusalem", title: "Asia, Jerusalem (GMT +3)" },
    { value: "Asia/Kabul", title: "Asia, Kabul (GMT +4,5)" },
    { value: "Asia/Kamchatka", title: "Asia, Kamchatka (GMT +12)" },
    { value: "Asia/Karachi", title: "Asia, Karachi (GMT +5)" },
    { value: "Asia/Kathmandu", title: "Asia, Kathmandu (GMT +5,75)" },
    { value: "Asia/Khandyga", title: "Asia, Khandyga (GMT +9)" },
    { value: "Asia/Kolkata", title: "Asia, Kolkata (GMT +5,5)" },
    { value: "Asia/Krasnoyarsk", title: "Asia, Krasnoyarsk (GMT +7)" },
    { value: "Asia/Kuala_Lumpur", title: "Asia, Kuala Lumpur (GMT +8)" },
    { value: "Asia/Kuching", title: "Asia, Kuching (GMT +8)" },
    { value: "Asia/Kuwait", title: "Asia, Kuwait (GMT +3)" },
    { value: "Asia/Macau", title: "Asia, Macau (GMT +8)" },
    { value: "Asia/Magadan", title: "Asia, Magadan (GMT +11)" },
    { value: "Asia/Makassar", title: "Asia, Makassar (GMT +8)" },
    { value: "Asia/Manila", title: "Asia, Manila (GMT +8)" },
    { value: "Asia/Muscat", title: "Asia, Muscat (GMT +4)" },
    { value: "Asia/Nicosia", title: "Asia, Nicosia (GMT +3)" },
    { value: "Asia/Novokuznetsk", title: "Asia, Novokuznetsk (GMT +7)" },
    { value: "Asia/Novosibirsk", title: "Asia, Novosibirsk (GMT +7)" },
    { value: "Asia/Omsk", title: "Asia, Omsk (GMT +6)" },
    { value: "Asia/Oral", title: "Asia, Oral (GMT +5)" },
    { value: "Asia/Phnom_Penh", title: "Asia, Phnom Penh (GMT +7)" },
    { value: "Asia/Pontianak", title: "Asia, Pontianak (GMT +7)" },
    { value: "Asia/Pyongyang", title: "Asia, Pyongyang (GMT +9)" },
    { value: "Asia/Qatar", title: "Asia, Qatar (GMT +3)" },
    { value: "Asia/Qyzylorda", title: "Asia, Qyzylorda (GMT +5)" },
    { value: "Asia/Riyadh", title: "Asia, Riyadh (GMT +3)" },
    { value: "Asia/Sakhalin", title: "Asia, Sakhalin (GMT +11)" },
    { value: "Asia/Samarkand", title: "Asia, Samarkand (GMT +5)" },
    { value: "Asia/Seoul", title: "Asia, Seoul (GMT +9)" },
    { value: "Asia/Shanghai", title: "Asia, Shanghai (GMT +8)" },
    { value: "Asia/Singapore", title: "Asia, Singapore (GMT +8)" },
    { value: "Asia/Srednekolymsk", title: "Asia, Srednekolymsk (GMT +11)" },
    { value: "Asia/Taipei", title: "Asia, Taipei (GMT +8)" },
    { value: "Asia/Tashkent", title: "Asia, Tashkent (GMT +5)" },
    { value: "Asia/Tbilisi", title: "Asia, Tbilisi (GMT +4)" },
    { value: "Asia/Tehran", title: "Asia, Tehran (GMT +3,5)" },
    { value: "Asia/Thimphu", title: "Asia, Thimphu (GMT +6)" },
    { value: "Asia/Tokyo", title: "Asia, Tokyo (GMT +9)" },
    { value: "Asia/Tomsk", title: "Asia, Tomsk (GMT +7)" },
    { value: "Asia/Ulaanbaatar", title: "Asia, Ulaanbaatar (GMT +8)" },
    { value: "Asia/Urumqi", title: "Asia, Urumqi (GMT +6)" },
    { value: "Asia/Ust-Nera", title: "Asia, Ust-Nera (GMT +10)" },
    { value: "Asia/Vientiane", title: "Asia, Vientiane (GMT +7)" },
    { value: "Asia/Vladivostok", title: "Asia, Vladivostok (GMT +10)" },
    { value: "Asia/Yakutsk", title: "Asia, Yakutsk (GMT +9)" },
    { value: "Asia/Yangon", title: "Asia, Yangon (GMT +6,5)" },
    { value: "Asia/Yekaterinburg", title: "Asia, Yekaterinburg (GMT +5)" },
    { value: "Asia/Yerevan", title: "Asia, Yerevan (GMT +4)" },
    { value: "Atlantic/Azores", title: "Atlantic, Azores (GMT 0)" },
    { value: "Atlantic/Bermuda", title: "Atlantic, Bermuda (GMT -3)" },
    { value: "Atlantic/Canary", title: "Atlantic, Canary (GMT +1)" },
    { value: "Atlantic/Cape_Verde", title: "Atlantic, Cape Verde (GMT -1)" },
    { value: "Atlantic/Faroe", title: "Atlantic, Faroe (GMT +1)" },
    { value: "Atlantic/Madeira", title: "Atlantic, Madeira (GMT +1)" },
    { value: "Atlantic/Reykjavik", title: "Atlantic, Reykjavik (GMT 0)" },
    { value: "Atlantic/South_Georgia", title: "Atlantic, South Georgia (GMT -2)" },
    { value: "Atlantic/St_Helena", title: "Atlantic, St Helena (GMT 0)" },
    { value: "Atlantic/Stanley", title: "Atlantic, Stanley (GMT -3)" },
    { value: "Australia/Adelaide", title: "Australia, Adelaide (GMT +9,5)" },
    { value: "Australia/Brisbane", title: "Australia, Brisbane (GMT +10)" },
    { value: "Australia/Broken_Hill", title: "Australia, Broken Hill (GMT +9,5)" },
    { value: "Australia/Darwin", title: "Australia, Darwin (GMT +9,5)" },
    { value: "Australia/Eucla", title: "Australia, Eucla (GMT +8,75)" },
    { value: "Australia/Hobart", title: "Australia, Hobart (GMT +10)" },
    { value: "Australia/Lindeman", title: "Australia, Lindeman (GMT +10)" },
    { value: "Australia/Lord_Howe", title: "Australia, Lord Howe (GMT +10,5)" },
    { value: "Australia/Melbourne", title: "Australia, Melbourne (GMT +10)" },
    { value: "Australia/Perth", title: "Australia, Perth (GMT +8)" },
    { value: "Australia/Sydney", title: "Australia, Sydney (GMT +10)" },
    { value: "Europe/Amsterdam", title: "Europe, Amsterdam (GMT +2)" },
    { value: "Europe/Andorra", title: "Europe, Andorra (GMT +2)" },
    { value: "Europe/Astrakhan", title: "Europe, Astrakhan (GMT +4)" },
    { value: "Europe/Athens", title: "Europe, Athens (GMT +3)" },
    { value: "Europe/Belgrade", title: "Europe, Belgrade (GMT +2)" },
    { value: "Europe/Berlin", title: "Europe, Berlin (GMT +2)" },
    { value: "Europe/Bratislava", title: "Europe, Bratislava (GMT +2)" },
    { value: "Europe/Brussels", title: "Europe, Brussels (GMT +2)" },
    { value: "Europe/Bucharest", title: "Europe, Bucharest (GMT +3)" },
    { value: "Europe/Budapest", title: "Europe, Budapest (GMT +2)" },
    { value: "Europe/Busingen", title: "Europe, Busingen (GMT +2)" },
    { value: "Europe/Chisinau", title: "Europe, Chisinau (GMT +3)" },
    { value: "Europe/Copenhagen", title: "Europe, Copenhagen (GMT +2)" },
    { value: "Europe/Dublin", title: "Europe, Dublin (GMT +1)" },
    { value: "Europe/Gibraltar", title: "Europe, Gibraltar (GMT +2)" },
    { value: "Europe/Guernsey", title: "Europe, Guernsey (GMT +1)" },
    { value: "Europe/Helsinki", title: "Europe, Helsinki (GMT +3)" },
    { value: "Europe/Isle_of_Man", title: "Europe, Isle of Man (GMT +1)" },
    { value: "Europe/Istanbul", title: "Europe, Istanbul (GMT +3)" },
    { value: "Europe/Jersey", title: "Europe, Jersey (GMT +1)" },
    { value: "Europe/Kaliningrad", title: "Europe, Kaliningrad (GMT +2)" },
    { value: "Europe/Kirov", title: "Europe, Kirov (GMT +3)" },
    { value: "Europe/Kyiv", title: "Europe, Kyiv (GMT +3)" },
    { value: "Europe/Lisbon", title: "Europe, Lisbon (GMT +1)" },
    { value: "Europe/Ljubljana", title: "Europe, Ljubljana (GMT +2)" },
    { value: "Europe/London", title: "Europe, London (GMT +1)" },
    { value: "Europe/Luxembourg", title: "Europe, Luxembourg (GMT +2)" },
    { value: "Europe/Madrid", title: "Europe, Madrid (GMT +2)" },
    { value: "Europe/Malta", title: "Europe, Malta (GMT +2)" },
    { value: "Europe/Mariehamn", title: "Europe, Mariehamn (GMT +3)" },
    { value: "Europe/Minsk", title: "Europe, Minsk (GMT +3)" },
    { value: "Europe/Monaco", title: "Europe, Monaco (GMT +2)" },
    { value: "Europe/Moscow", title: "Europe, Moscow (GMT +3)" },
    { value: "Europe/Oslo", title: "Europe, Oslo (GMT +2)" },
    { value: "Europe/Paris", title: "Europe, Paris (GMT +2)" },
    { value: "Europe/Podgorica", title: "Europe, Podgorica (GMT +2)" },
    { value: "Europe/Prague", title: "Europe, Prague (GMT +2)" },
    { value: "Europe/Riga", title: "Europe, Riga (GMT +3)" },
    { value: "Europe/Rome", title: "Europe, Rome (GMT +2)" },
    { value: "Europe/Samara", title: "Europe, Samara (GMT +4)" },
    { value: "Europe/San_Marino", title: "Europe, San Marino (GMT +2)" },
    { value: "Europe/Sarajevo", title: "Europe, Sarajevo (GMT +2)" },
    { value: "Europe/Saratov", title: "Europe, Saratov (GMT +4)" },
    { value: "Europe/Simferopol", title: "Europe, Simferopol (GMT +3)" },
    { value: "Europe/Skopje", title: "Europe, Skopje (GMT +2)" },
    { value: "Europe/Sofia", title: "Europe, Sofia (GMT +3)" },
    { value: "Europe/Stockholm", title: "Europe, Stockholm (GMT +2)" },
    { value: "Europe/Tallinn", title: "Europe, Tallinn (GMT +3)" },
    { value: "Europe/Tirane", title: "Europe, Tirane (GMT +2)" },
    { value: "Europe/Ulyanovsk", title: "Europe, Ulyanovsk (GMT +4)" },
    { value: "Europe/Vaduz", title: "Europe, Vaduz (GMT +2)" },
    { value: "Europe/Vatican", title: "Europe, Vatican (GMT +2)" },
    { value: "Europe/Vienna", title: "Europe, Vienna (GMT +2)" },
    { value: "Europe/Vilnius", title: "Europe, Vilnius (GMT +3)" },
    { value: "Europe/Volgograd", title: "Europe, Volgograd (GMT +3)" },
    { value: "Europe/Warsaw", title: "Europe, Warsaw (GMT +2)" },
    { value: "Europe/Zagreb", title: "Europe, Zagreb (GMT +2)" },
    { value: "Europe/Zurich", title: "Europe, Zurich (GMT +2)" },
    { value: "Indian/Antananarivo", title: "Indian, Antananarivo (GMT +3)" },
    { value: "Indian/Chagos", title: "Indian, Chagos (GMT +6)" },
    { value: "Indian/Christmas", title: "Indian, Christmas (GMT +7)" },
    { value: "Indian/Cocos", title: "Indian, Cocos (GMT +6,5)" },
    { value: "Indian/Comoro", title: "Indian, Comoro (GMT +3)" },
    { value: "Indian/Kerguelen", title: "Indian, Kerguelen (GMT +5)" },
    { value: "Indian/Mahe", title: "Indian, Mahe (GMT +4)" },
    { value: "Indian/Maldives", title: "Indian, Maldives (GMT +5)" },
    { value: "Indian/Mauritius", title: "Indian, Mauritius (GMT +4)" },
    { value: "Indian/Mayotte", title: "Indian, Mayotte (GMT +3)" },
    { value: "Indian/Reunion", title: "Indian, Reunion (GMT +4)" },
    { value: "Pacific/Apia", title: "Pacific, Apia (GMT +13)" },
    { value: "Pacific/Auckland", title: "Pacific, Auckland (GMT +12)" },
    { value: "Pacific/Bougainville", title: "Pacific, Bougainville (GMT +11)" },
    { value: "Pacific/Chatham", title: "Pacific, Chatham (GMT +12,75)" },
    { value: "Pacific/Chuuk", title: "Pacific, Chuuk (GMT +10)" },
    { value: "Pacific/Easter", title: "Pacific, Easter (GMT -6)" },
    { value: "Pacific/Efate", title: "Pacific, Efate (GMT +11)" },
    { value: "Pacific/Fakaofo", title: "Pacific, Fakaofo (GMT +13)" },
    { value: "Pacific/Fiji", title: "Pacific, Fiji (GMT +12)" },
    { value: "Pacific/Funafuti", title: "Pacific, Funafuti (GMT +12)" },
    { value: "Pacific/Galapagos", title: "Pacific, Galapagos (GMT -6)" },
    { value: "Pacific/Gambier", title: "Pacific, Gambier (GMT -9)" },
    { value: "Pacific/Guadalcanal", title: "Pacific, Guadalcanal (GMT +11)" },
    { value: "Pacific/Kiritimati", title: "Pacific, Kiritimati (GMT +14)" },
    { value: "Pacific/Noumea", title: "Pacific, Noumea (GMT +11)" },
    { value: "Pacific/Port_Moresby", title: "Pacific, Port Moresby (GMT +10)" },
    { value: "Pacific/Kosrae", title: "Pacific, Kosrae (GMT +11)" },
    { value: "Pacific/Marquesas", title: "Pacific, Marquesas (GMT -09:30)" },
    { value: "Pacific/Pago_Pago", title: "Pacific, Pago Pago (GMT -11)" },
    { value: "Pacific/Palau", title: "Pacific, Palau (GMT +9)" },
    { value: "Pacific/Pitcairn", title: "Pacific, Pitcairn (GMT -8)" },
    { value: "Pacific/Pohnpei", title: "Pacific, Pohnpei (GMT +11)" },
    { value: "Pacific/Rarotonga", title: "Pacific, Rarotonga (GMT -10)" },
    { value: "Pacific/Saipan", title: "Pacific, Saipan (GMT +10)" },
    { value: "Pacific/Tahiti", title: "Pacific, Tahiti (GMT -10)" },
    { value: "Pacific/Tarawa", title: "Pacific, Tarawa (GMT +12)" },
    { value: "Pacific/Tongatapu", title: "Pacific, Tongatapu (GMT +13)" },
    { value: "Pacific/Wake", title: "Pacific, Wake (GMT +12)" },
    { value: "Pacific/Wallis", title: "Pacific, Wallis (GMT +12)" },
    { value: "UTC", title: "UTC (GMT 0)" }
];

export default timezones;