import React from 'react';
import { StyledButton } from './styles';

const Button = ({ variant, type, onClick, disabled, startIcon, children }) => {
    return (
        <StyledButton variant={variant} type={type} onClick={onClick} disabled={disabled} startIcon={startIcon}> 
            {children}
        </StyledButton>
    );
}

export default Button;
