import React from 'react';
import Checkbox from '../Layout/Checkbox';

const FilterDevice = ({...props}) => {
    const devices = ['Batida Online', 'Batida Online (padrão)', "Multi", "My Ahgora"];
    const handleChange = (device) => {
        const exists = props.filterDevice.findIndex(d => d === device);
        if (exists > -1) {
            props.setFilterDevice(props.filterDevice.filter(d => d !== device));
            return;
        }
        props.setFilterDevice([...props.filterDevice, device]);
        return;
    }
    return (
        <div style={{marginLeft: 16}}>
            {devices.map((device, index) => 
                    <Checkbox 
                        sx={{display: 'flex'}}
                        label={device} 
                        key={index} 
                        onChange={()=> handleChange(device)}
                        checked={props.filterDevice.findIndex(d => d === device) > -1}
                    />
            )}
        </div>
    );
};

export default FilterDevice;