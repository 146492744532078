const options = {
    credentials: 'include',
    crossDomain: true,
    headers: { "Content-Type": "application/json"}
}

const api ={
post : async (url, body = {}) => {
    try {
        options.method = 'post';
        options.body = JSON.stringify(body);
        const response = await fetch(url, options);
        return response.json();
    } catch (e) {
        console.log('Error', e);
        return false;
    }
}, get: async (url) => {
    try {
        options.method = 'get';
        const response = await fetch(url, options);
        return await response.json();
    } catch (e) {
        console.log('Error', e);
        return false;
    }
}
}

export default api;
