import React from 'react';
import { Switch as MuiSwitch } from '@mui/material';
import { Switch as CustomSwitch } from './styles';

const Switch = ({ ...props }) => {
    return (
        <CustomSwitch
            control={ 
                <MuiSwitch checked={props.checked} /> 
            }
            label={ props.label }
            onChange={props.onChange}
            disabled={ props.disabled }
            onBlur={props.onBlur}
            onError={props.onError}
        />
    );
};

export default Switch;