import { useState } from 'react';


const useFilter = () => {
    const [filterDate, setFilterDate] = useState({ start: null, end: null })
    const [filterDevice, setFilterDevice] = useState([])
    const [filterStatus, setFilterStatus] = useState(null)
    const [filterList, setFilterList] = useState([]);

    const toggleFilterItem = (filter, add = false) => {
        const filterListAtt = filterList.filter((item) => item.type !== filter.type);
        if (add) {
            setFilterList([...filterListAtt, filter]);
            return;
        } else {
            setFilterList(filterListAtt);
            clearFilter(filter.type)
        }
    }

    const clearFilter = (filterType) => {
        switch (filterType) {
            case 'signinDate':
                setFilterDate({ start: null, end: null })
                break;
            case 'deviceType':
                setFilterDevice([])
                break;
            case 'status':
                setFilterStatus(null)
                break;
            default:
                break;
        }
    }

    return { filterDate, setFilterDate, filterDevice, setFilterDevice, filterStatus, setFilterStatus, filterList, toggleFilterItem, clearFilter }
}

export default useFilter