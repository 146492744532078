import { styled, Paper } from '@mui/material';
import { styled as styledComponent } from 'styled-components';

export const Helper = styled(Paper)({
    backgroundColor: 'var(--ahg-gray-lighten4)',
    color: 'var(--ahg-secondary-base)',
    fontFamily: 'Open Sans',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    padding: 16,
    paddingLeft: 32,
    "& span.adjustMark": {
        marginLeft: -6,
    }
});

export const Divider = styledComponent.div`
    width: calc(50% - 32px);
    :not(:first-child): {
        margin-left: 100px;
    }
`;