import React from 'react';
import styled from 'styled-components';

const Container = ({ children }) => {
    return (
        <PageContainer>
            { children }
        </PageContainer>
    )
};

const PageContainer = styled.div`
    margin: 16px;
    max-width: calc(100% - 32px);
    background-color: #FFF;
    border-radius: 4px;
    min-height: 300px;
    padding: 24px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-wrap: wrap;
`;

export default Container;