import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import i18n from '../../helpers/i18n';
import Header from '../../components/Header/Header';
import CreateDeviceContent from '../../components/CreateDeviceContent/';
import MultipleDevicesContent from '../../components/MultipleDevicesContent';
import EditDeviceContent from '../../components/EditDeviceContent/';

const CreateEditPage = () => {
    const location = useLocation();
    const path = location.pathname.split('/')[1];

    const [deviceName, setDeviceName] = useState('');

    const handleDeviceNameFetched = (name) => {
        setDeviceName(name);
    };

    const title = {
        'create': i18n.t('pages.newDevice.title'),
        'edit': i18n.t('pages.editDevice.title'),
        'multiple': i18n.t('pages.multipleDevice.title')
    };

    const breadCrumbs = [
        { title: i18n.t('home.title'), href: "/" },
        { title: title[path] }
    ];

    const RenderContent = () => {
        switch (path) {
            case 'multiple':
                return (<MultipleDevicesContent />);
            case 'create':
                return (<CreateDeviceContent />);
            case 'edit':
                return (<EditDeviceContent onDeviceNameFetched={handleDeviceNameFetched} />);
            default:
                return (<></>);
        }
    };

    return (
        <div>
            <Header title={title[path]} breadCrumbs={breadCrumbs} deviceName={deviceName}/>
            <RenderContent />
        </div>
    );
};

export default CreateEditPage;
