import AlertModal from '../AlertModal';

export default function DeviceDeleteAlert({ handleDelete, isOpen , setIsOpenDeleteModal}) {
    return (
        <AlertModal
            title="Arquivar o dispositivo"
            buttonAction={handleDelete}
            buttonText='Arquivar dispositivo'
            buttonColor='error'
            cancelText='Cancelar'
            children={`Tem certeza que deseja arquivar o dispositivo?`}
            cancelAction={() => setIsOpenDeleteModal(false)}
            visible={isOpen} />
    )
}