import { styled, FormControlLabel } from "@mui/material";

export const Switch = styled(FormControlLabel)({
    marginTop: 0,
    marginLeft: -6,
    "& .MuiFormControlLabel-label" : {
        color: 'var(--ahg-waterloo-darken1)',
        fontFamily: 'Open Sans',
        fontSize: 14,
        "&.Mui-disabled": {
            color: 'var(--ahg-waterloo-lighten1)'
        }
    },
    "& .Mui-checked": {
        color: 'var(--ahg-primary-base)'
    },
    "& .MuiSwitch-track": {
        backgroundColor: 'var(--ahg-waterloo-darken1)'
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: 'var(--ahg-primary-lighten1)'
    }
});