import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const TooltipStyled = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'var(--ahg-waterloo-darken1)',
      boxShadow: theme.shadows[1],
      fontFamily: 'Open Sans',
      fontSize: 14,
      padding: 16,
      borderRadius: 4,
      lineHeight: '19.07px',
    }, 
    [`& .MuiTooltip-arrow`]: {
        color: 'var(--ahg-waterloo-darken1) !important',
      },
  }));

export const infoStyle = {
    fontSize: '20px',
    color: 'var(--ahg-waterloo-darken1)',
}