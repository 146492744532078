import React, { useEffect, useState, useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Container, Button } from '../Layout';
import { gridContainerStyle } from '../CreateDeviceContent/styles';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import i18n from '../../helpers/i18n';
import { getEmployees, getDevice, getLocationsAndCnpj, editDevice } from '../CreateDeviceContent/CreateDeviceContent.queries';
import { useFormik } from 'formik';
import { validationSchema } from '../CreateDeviceContent/validationSchema';
import { RestrictionSection, AuthorizedCollaboratorsSection, ControllerSection, DeviceSection } from '../CreateEditSections/';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { deviceTypes, filterTypes } from '../../helpers/consts/deviceConstants';
import { useNavigate } from 'react-router-dom';


const EditDeviceContent = ({ onDeviceNameFetched }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [offset, setOffset] = useState(0);

    const { data: dataDevice } = useQuery({
        queryKey: [id],
        queryFn: () => getDevice({ identity: id }),
        retry: false,
        refetchOnWindowFocus: false
    });

    const { data: dataEmployees, isLoading: isLoadingEmployees } = useQuery({
        queryKey: ['funcionarios', offset],
        queryFn: () => getEmployees({ limit: 20, offset }),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: false
    });

    const { data: dataLocationsAndCnpj, isLoading: isLoadingLocationsAndCnpjs } = useQuery({
        queryKey: ['locations'],
        queryFn: getLocationsAndCnpj,
        refetchOnWindowFocus: false,
        retry: false
    });

    const [employees, setEmployees] = useState([]);
    const [locations, setLocations] = useState([]);
    const [cnpjs, setCnpjs] = useState([]);

    const formik = useFormik({
        initialValues: {
            identity: '',
            deviceType: '',
            cnpj: '',
            name: '',
            company: '',
            timezone: '',
            activationKey: '',
            active: true,
            expirationDate: false,
            startPeriod: null,
            endPeriod: null,

            collaboratorsType: 'by_employee',
            employees: [],
            locations: [],

            //Crontroller
            enable_password_punch: false, //Ativar batida por matrícula e senha
            break_device: false, //Ativar dispositivo de pausa NR17
            enable_auto_update: false, //Ativar atualização automática (Tablet Ahgora)
            default_computer: false, //Ativar batida online padrão por link
            timezone_filter: false,  //Ativar fuso horário como filtro              
            triple_validation: false, //Ativar validação tripla para múltiplos usuários
            enable_punches_logs: false, //ativar logs de batidas
            enable_punch_backup: false, //Ativar download de arquivo com batida de ponto (Solicitar donwload do arquivo)

            //Restriction
            online_only: false, //Com o dispositivo conectado à internet
            auto_timezone: false, //Com data, hora e fuso automáticos
            only_with_location: false, //Com geolocalização no dispositivo
            range_ip: false,
            ips: [], //Ip Range '123.123.123.23, 123.123.123.24'
        },
        validationSchema,
        onSubmit: values => {
            mutationEditDevice.mutate({identity: id, deviceData: values});
        }
    });

    const prevDataDeviceRef = useRef();
    useEffect(() => {
        if (dataDevice && prevDataDeviceRef.current !== dataDevice) {
            prevDataDeviceRef.current = dataDevice;
            onDeviceNameFetched(dataDevice.name)
            formik.setValues({
                ...formik.values,
                identity: dataDevice.identity,
                company: dataDevice.company,
                deviceType: dataDevice.device_type || '',
                cnpj: dataDevice.cnpj || '',
                name: dataDevice.name || '',
                timezone: dataDevice.timezone || '',
                activationKey: dataDevice.activationKey || '',
                active: dataDevice.active ?? true,
                expirationDate: dataDevice.startPeriod ? true : false,
                startPeriod: dataDevice.startPeriod ? dayjs.unix(dataDevice.startPeriod) : null,
                endPeriod: dataDevice.endPeriod ? dayjs.unix(dataDevice.endPeriod) : null,
                collaboratorsType: dataDevice.locations?.length > 0 ? 'by_location' : 'by_employee',
                employees: dataDevice.employees || [],
                locations: dataDevice.locations || [],

                // Controller
                enable_password_punch: dataDevice.enable_password_punch ?? false,
                break_device: dataDevice.break_device ?? false,
                enable_auto_update: dataDevice.enable_auto_update ?? false,
                default_computer: dataDevice.default_computer ?? false,
                timezone_filter: dataDevice.timezone_filter ?? false,
                triple_validation: dataDevice.triple_validation ?? false,
                enable_punches_logs: dataDevice.enable_punches_logs ?? false,
                enable_punch_backup: dataDevice.enable_punch_backup ?? false,

                // Restriction
                online_only: dataDevice.online_only ?? false,
                auto_timezone: dataDevice.auto_timezone ?? false,
                only_with_location: dataDevice.only_with_location ?? false,
                range_ip: dataDevice.range_ip ? true : false,
                ips: dataDevice.range_ip ? dataDevice.range_ip.split(',') : [],
            });
        }
    }, [dataDevice, formik, onDeviceNameFetched]);

    useEffect(() => {
        if (dataLocationsAndCnpj) {
            const locations = dataLocationsAndCnpj.locations.map(location => ({ value: location, title: location }));
            setLocations(locations);
            setCnpjs(dataLocationsAndCnpj.cnpjs.map(cnpj => ({ value: cnpj, title: cnpj })));
        }
    }, [dataLocationsAndCnpj]);

    useEffect(() => {
        if (dataEmployees?.data) {
            const newEmployees = dataEmployees.data.map(employee => ({
                value: employee.dados.matricula,
                title: `${employee.dados.matricula} | ${employee.dados.nome}`
            }));

            setEmployees(prevEmployees => [
                ...prevEmployees,
                ...newEmployees
            ]);
        }
    }, [dataEmployees]);

    const mutationEditDevice = useMutation({
        mutationFn: editDevice,
        onSuccess: () => {
            alert('Dispositivo atualizado com sucesso!');
        },
        onError: (e) => {
            alert('Falha ao atualizado dispositivo.', e);
        },
    });

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight;
        if (bottom) {
            setOffset(offset + 1);
        }
    };

    return (
        <Container>
            <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
                <Grid sx={gridContainerStyle} container spacing={10}>

                    <DeviceSection
                        formik={formik}
                        cnpjs={cnpjs}
                        deviceTypes={deviceTypes}
                        isLoadingLocationsAndCnpjs={isLoadingLocationsAndCnpjs}
                        variant='edit' />

                    <AuthorizedCollaboratorsSection
                        formik={formik}
                        employees={employees}
                        locations={locations}
                        isLoadingLocationsAndCnpjs={isLoadingLocationsAndCnpjs}
                        handleScroll={handleScroll}
                        isLoadingEmployees={isLoadingEmployees}
                        filterTypes={filterTypes}
                    />

                    <ControllerSection formik={formik} variant='edit' />

                    <RestrictionSection formik={formik} />
                </Grid>
                <Divider style={{ marginBottom: 16 }} />
                <Button type="outlined" onClick={(e) => {
                    e.preventDefault();
                    navigate('/');
                }}>{i18n.t('pages.newDevice.cancel')}</Button>
                <Button type="submit">{i18n.t('pages.newDevice.editDevice')}</Button>
            </form>
        </Container>
    );
};

export default EditDeviceContent;
