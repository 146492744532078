import React from 'react';
import { MenuItem } from '@mui/material';
import { CustomSelect, Label, Form } from './styles';
import { Checkbox, ListItemText, CircularProgress } from '@mui/material';

const SelectMultiple = ({ ...props }) => {
    const handleLabelHover = (action) => {
        const el = document.getElementById(`${props.id}-label`);
        if (action === 'enter') {
            el.classList.add('input-hovered');
        } else {
            el.classList.remove('input-hovered');
        }
    }
    return (
    <Form>
        <Label id={`${props.id}-label`} disabled={props.disabled} >{props.title}</Label>
        <CustomSelect
            multiple
            id={props.id}
            labelId={`${props.id}-label`}
            onMouseEnter={() => handleLabelHover('enter')}
            onMouseLeave={() => handleLabelHover('leave')}
            disabled={props.disabled}
            value={props.value}
            onChange={props.onChange}
            renderValue={props.renderValue}
            label={props.label}
            MenuProps={props.MenuProps}
        >
            {props.items.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                    <Checkbox checked={props.value.indexOf(item.value) > -1} />
                    <ListItemText primary={item.title} />
                </MenuItem>
            ))}
            {props.loading && (
                <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} disabled>
                    <CircularProgress size={24} />
                </MenuItem>
            )}

        </CustomSelect>
    </Form>
    );
}

export default SelectMultiple;